import {generateUUID, isEmptyString, isNull} from "../../utils/utils";
import {notifyError} from "../../utils/display.notifications";

export const validatePaymentData = (payment, indexMessage="") => {
    if (isEmptyString(payment.payment_mode)) {
        notifyError("createpayment", "Please select a payment mode "+indexMessage);
        return false;
    }

    if (isEmptyString(payment.transaction_number) && isNull(payment.generatedIRN)) {
        notifyError("createpayment", "Either IRN need to be generated or enter transaction ID "+indexMessage);
        return false;
    }
    return true;
}

export const handleAdditionalFilesForMultiplePayments = (files, payment_id, formData) => {
    Object.values(files).forEach((file, index) => {
        formData.append(payment_id, file);
    });
    return formData;
}

export const handleBillFilesForBillEntry = (files, formData, formKey) => {
    Object.values(files).forEach((file, index) => {
        formData.append(formKey, file);
    });
    return formData;
}

export const appendAdditionalFilesForMultiplePayments = (fileObject, formData) => {

}

export const appendBillFilesInFormData = () => {

}