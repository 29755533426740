import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useLocation, useNavigate} from 'react-router-dom';
import {Avatar, Button, Tooltip} from '@mui/material';
import Cookies from 'js-cookie';
import {useDispatch, useSelector} from 'react-redux';
import { logoutSuccess } from '../redux/actions/login.action';
import {getUserFromLocalStorage, isNull} from "../utils/utils";


const drawerWidth = 200;

export default function PermanentDrawerLeft({children}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const user = getUserFromLocalStorage();

    const formPages = [
        { text: 'Paid/Paying Now', path: '/pay-now' },
        { text: 'Outstanding Payment', path: '/pay-later' },
    ];

    const viewPages = [
        { text: 'Payments', path: '/payment-details' },
        { text: 'Bills', path: '/bill-details' },
    ];

    const miscelleneousPages = [
        { text: 'Upload Transactions', path: '/upload_transactions' },
    ];

      const handleNavigation = (path) => {
        navigate(path);
      };
      const handleLogout = () => {
        localStorage.clear();
        Cookies.remove("access_token");
        dispatch(logoutSuccess());
        navigate('/login');
    };

      let drawer = (
          <Drawer
              sx={{
                  width: drawerWidth,
                  flexShrink: 1,
                  '& .MuiDrawer-paper': {
                      width: drawerWidth,
                      boxSizing: 'border-box',
                  },
              }}
              variant="permanent"
              anchor="left"
          >
              <Toolbar />
              <Divider />
              <List>
                  <List>
                      {formPages.map((page, index) => (
                          <ListItem key={page.text} disablePadding>
                              <ListItemButton onClick={() => handleNavigation(page.path)}>
                                  <ListItemText primary={page.text} />
                              </ListItemButton>
                          </ListItem>
                      ))}
                      <Divider></Divider>
                      {viewPages.map((page, index) => (
                          <ListItem key={page.text} disablePadding>
                              <ListItemButton onClick={() => handleNavigation(page.path)}>
                                  <ListItemText primary={page.text} />
                              </ListItemButton>
                          </ListItem>
                      ))}
                      <Divider></Divider>
                      {miscelleneousPages.map((page, index) => (
                          <ListItem key={page.text} disablePadding>
                              <ListItemButton onClick={() => handleNavigation(page.path)}>
                                  <ListItemText primary={page.text} />
                              </ListItemButton>
                          </ListItem>
                      ))}
                  </List>
              </List>
          </Drawer>
      );

      if (location.pathname.startsWith('/login')) {
          drawer = null;
      }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        style={{backgroundColor: "black", zIndex:1300}}
        // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Morphle Payments
          </Typography>
          <div style={{flexGrow:1}}></div>
            {user?.username ? (
                <>
                    <Tooltip title={`Logged in as: ${user?.first_name} ${user?.last_name}`}>
                        <Avatar
                            alt={user?.username}
                            sx={{ width: 30, height: 30 }}
                            src={user?.picture}
                        >
                        </Avatar>
                    </Tooltip>
                    <Tooltip title="Logout">
                        <Button onClick={handleLogout} style={{color:'white'}}>Logout</Button>
                    </Tooltip>
                </>
            ) : null}
        </Toolbar>
      </AppBar>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1}}>
            <Box sx={{width: drawer ? `${drawerWidth}px` : 0, height: "100vh"}} >
                {drawer}
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, mt: 8}}
            >
                {children}
            </Box>
        </Box>
    </Box>
  );
}