import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel, FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React, {useEffect, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Divider from "@mui/material/Divider";
import FileUploadComponent from "../../components/FileUploadComponent";
import {getHumanReadableFileSize, isNull, notNull} from "../../utils/utils";
import {axiosInstance} from "../../utils/axiosConfig";
import {CREATE_BILL_ENTRY_API} from "../../utils/consts";
import {notifyError, notifySuccess, notifyWarning} from "../../utils/display.notifications";
import Backdrop from "@mui/material/Backdrop";
import {
    accountSelector,
    batchNumberSelector,
    enkashVendorSelector,
    fetchAccountList,
    fetchBatchList,
    fetchEnkashVendorList, fetchInvoiceNumberList
} from "../utils";
import dayjs from "dayjs";
import {CustomPopper} from "../../components/CustomPopper";

export const UploadBillForm = (props) => {
    const [formData, setFormData] = useState({
        paymentDueDate: dayjs(new Date()),
        firstPaymentTranche: null,
        totalAmount: null,
        billName: null,
        batchNumber:null,
        account: null,
        enkashVendorId: null,
        billFiles: [],
        additionalFiles: []
    })
    const [loading, setLoading] = useState(false);
    const [enkashInformationString, setEnkashInformationString] = useState("");
    const [enkashInvoiceAmount, setEnkashInvoiceAmount] = useState("");
    const [uploadToEnkash, setUploadToEnkash] = useState(true);
    const [cacheDataCompulsory, setCacheDataCompulsory] = useState(false);
    const [vendorList, setVendorList] = useState([])
    const [accountList, setAccountList] = useState([])
    const [batchList, setBatchList] = useState([])
    const [accountListSearchMeta, setAccountListSearchMeta] = useState({
        searchQuery: ''
    });
    const [batchListSearchMeta, setBatchListSearchMeta] = useState({
        searchQuery: ''
    });
    const [vendorListSearchMeta, setVendorListSearchMeta] = useState({
        searchQuery: ''
    });


    useEffect(() => {
        fetchAccountList(setAccountList, accountListSearchMeta.searchQuery);
    }, [accountListSearchMeta]);

    useEffect(() => {
        if (notNull(formData.totalAmount) && notNull(formData.firstPaymentTranche) && !isNaN(formData.firstPaymentTranche) && notNull(formData.paymentDueDate)){
            let invoiceAmount = Math.round(formData.totalAmount*(formData.firstPaymentTranche/100)*100)/100;
            setEnkashInvoiceAmount(invoiceAmount)
            setEnkashInformationString(`Selecting this option will create invoice on Enkash for \n
            Amount = ${invoiceAmount}\n
            and due date = ${formData.paymentDueDate.format("DD/MM/YYYY")}`);
        } else {
            setEnkashInformationString("Selecting this option will create invoice on Enkash for" +
                " the tranche payment with amount and the due date")
            setEnkashInvoiceAmount(null)
        }
    }, [formData.totalAmount, formData.firstPaymentTranche, formData.paymentDueDate]);

    useEffect(() => {
        fetchBatchList(setBatchList, batchListSearchMeta.searchQuery);
    }, [batchListSearchMeta]);

    useEffect(() => {
        setLoading(true);
        fetchEnkashVendorList(setVendorList, vendorListSearchMeta.searchQuery, cacheDataCompulsory, setLoading);
        if (!cacheDataCompulsory) {
            setCacheDataCompulsory(true);
        }
    }, [vendorListSearchMeta]);


    const handleAccountListSearchChange = (e) => {
        setAccountListSearchMeta({
            ...accountListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleVendorListSearchChange = (e) => {
        setVendorListSearchMeta({
            ...vendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleBatchListSearchChange = (e) => {
        setBatchListSearchMeta({
            ...batchListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleAccountChange = (account_details) => {
        setFormData({
            ...formData,
            account: account_details
        })
    }

    const handleBatchChange = (batch_details) => {
        setFormData({
            ...formData,
            batchNumber: batch_details
        })
    }

    const handleVendorIdChange = (enkashVendor) => {
        let enkashVendorId = null;
        if (enkashVendor) {
            enkashVendorId = enkashVendor.company_id;
        }
        setFormData({
            ...formData,
            enkashVendorId: enkashVendorId,
        })
    }

    const handleFileUploads = (files, formData, key) => {
        files.forEach((file) => {
            formData.append(key, file);
        });
    };

    const validateFormData = (formData) => {
        if (isNull(formData.paymentDueDate)) {
            notifyError("editbill", "Due Date Cannot be Empty!");
            return false;
        }

        if (uploadToEnkash && (isNull(enkashInvoiceAmount) || isNaN(enkashInvoiceAmount))) {
            notifyError("editbill", "First Tranche Payment Cannot be Empty!");
            return false;
        }

        if (isNull(formData.totalAmount) || isNaN(formData.totalAmount)) {
            notifyError("editbill", "Pending Amount Cannot be Empty!");
            return false;
        }

        if (isNull(formData.billName)) {
            notifyError("editbill", "Invoice Number Cannot be Empty!");
            return false;
        }

        if (isNull(formData.account)) {
            notifyError("editbill", "Account Cannot be Empty!");
            return false;
        }

        if (formData.billFiles.length === 0 && formData.additionalFiles.length === 0) {
            notifyError("editbill", "Cannot Upload Bill without files!");
            return false;
        }

        if (uploadToEnkash && isNull(formData.enkashVendorId)) {
            notifyError("editbill", "Select Enkash Vendor for uploading the bill to enkash!");
            return false;
        }

        return true;
    };

    const appendFormData = (formData, postFormData) => {
        postFormData.append("paymentDueDate", formData.paymentDueDate.toISOString());
        postFormData.append("invoiceNumber", formData.billName);
        postFormData.append("batchNumber", JSON.stringify(formData.batchNumber));
        postFormData.append("account", JSON.stringify(formData.account));
        postFormData.append("firstPaymentTranche", enkashInvoiceAmount);
        postFormData.append("totalAmount", formData.totalAmount);
        postFormData.set("payLaterLink", window.location.href)
        if (uploadToEnkash) {
            postFormData.append("enkashVendorId", formData.enkashVendorId);
        }
    };

    const uploadBillData = () => {
        const postFormData = new FormData();

        if (!validateFormData(formData)) return;
        handleFileUploads(formData.billFiles, postFormData, "billFiles");
        handleFileUploads(formData.additionalFiles, postFormData, "additionalFiles");

        appendFormData(formData, postFormData);

        setLoading(true);

        axiosInstance.post(CREATE_BILL_ENTRY_API, postFormData)
            .then((resp) => {
                if (resp.data.data.bill_files_upload_failed) {
                    notifyWarning("billcreate", "Failed to upload bill files to cloud, you might need to retry.");
                }
                notifySuccess("billcreate", "Bill Entry created successfully, redirecting to Bill edit page");
                setTimeout(() => {
                    window.location.href = `/bill/edit/${resp.data.data.id}`;
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
                notifyError("billcreate", err.response.data.msg);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDateChange = (newDate) => {
        setFormData({
            ...formData,
            paymentDueDate: newDate
        })
    };

    const handleNameChange = (newName) => {
        setFormData({
            ...formData,
            billName: newName
        })
    };

    const handleFirstPaymentPercentageChange = (e) => {
        let validValue = "";
        const value = e.target.value;
        if (value !== "") {
            const floatValue = parseFloat(value);
            if (floatValue > 100) {
                validValue = formData.firstPaymentTranche;
            } else if (floatValue < 0) {
                validValue = formData.firstPaymentTranche || 0;
            } else {
                validValue = floatValue;
            }
        }
        setFormData({
            ...formData,
            firstPaymentTranche: validValue
        })
    };

    const handleDueAmountChange = (e) => {
        const val = parseFloat(e.target.value);
        const validValue = val >= 0 ? val : e.target.value === "" ? null : 0
        setFormData({
            ...formData,
            totalAmount: validValue
        })
    };

    const handleBillFileChange = (e) => {
        setFormData({
            ...formData,
            billFiles: Array.from(e.target.files)
        })
    }

    const handleAdditionalFileChange = (e, index) => {
        setFormData({
            ...formData,
            additionalFiles: Array.from(e.target.files)
        })
    }

    function handleFirstPaymentAmountChange(e) {
        let validValue = "";
        const value = e.target.value;
        if (value !== "") {
            const floatValue = parseFloat(value);
            if (floatValue > formData.totalAmount) {
                validValue = formData.totalAmount;
            } else if (floatValue < 0) {
                validValue = enkashInvoiceAmount || 0;
            } else {
                validValue = floatValue;
            }
        }
        setFormData({
            ...formData,
            firstPaymentTranche: (validValue/formData.totalAmount)*100
        })
    }

    return (
        <Box sx={{width: "100%", marginRight: "100px"}}>
           <Typography variant="h4" >Upload New Bill</Typography>
            <Divider style={{marginTop: "10px", marginBottom: "30px"}}></Divider>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <Box sx={{display: "flex", alignItems: "center", padding: 2, gap: 2}}>
                        <Box sx={{ flex: 1 }}>
                            <TextField
                                label={"Invoice Number"}
                                onChange={(event) => handleNameChange(event.target.value)}
                                value={formData.billName}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <TextField
                                type="number"
                                value={formData.totalAmount}
                                InputLabelProps={{
                                    shrink: !(isNull(formData.totalAmount) || isNaN(formData.totalAmount)),
                                }}
                                onChange={handleDueAmountChange} label={"Total amount"} >
                            </TextField>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <TextField
                                type="number"
                                value={formData.firstPaymentTranche}
                                InputLabelProps={{
                                    shrink: !(isNull(formData.firstPaymentTranche) || isNaN(formData.firstPaymentTranche)),
                                }}
                                disabled={isNull(formData.totalAmount) || !uploadToEnkash}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                onChange={handleFirstPaymentPercentageChange} label={"Tranche payment"} >
                            </TextField>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <TextField
                                type="number"
                                value={enkashInvoiceAmount}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">₹</InputAdornment>
                                }}
                                InputLabelProps={{
                                    shrink: !(isNull(enkashInvoiceAmount) || isNaN(enkashInvoiceAmount)),
                                }}
                                disabled={isNull(formData.totalAmount) || !uploadToEnkash}
                                onChange={handleFirstPaymentAmountChange} label={"Tranche payment"} >
                            </TextField>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Tranche payment due date"
                                    value={formData.paymentDueDate}
                                    minDate={dayjs(new Date())}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            {accountSelector(false, accountList, formData.account, handleAccountChange, handleAccountListSearchChange)}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            {batchNumberSelector(false, batchList, formData.batchNumber, handleBatchChange, handleBatchListSearchChange)}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <Box sx={{ mb: 2 }}>
                                <FileUploadComponent
                                    text={"Attach bill files"}
                                    files={formData.billFiles}
                                    handleFileChange={handleBillFileChange}
                                    allowFiles={"*"}
                                    showFiles={false}
                                    index={"bill-files"}
                                />
                                <FormHelperText sx={{color: "red", fontSize: "small"}}>
                                    Only attach the taxed invoice document in the bill files
                                </FormHelperText>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <FileUploadComponent
                                    text={"Attach additional files"}
                                    files={formData.additionalFiles}
                                    handleFileChange={handleAdditionalFileChange}
                                    allowFiles={"*"}
                                    showFiles={false}
                                    index={"additional-files"}
                                />
                                <FormHelperText>
                                    Attach any other documents in additional files
                                </FormHelperText>
                            </Box>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={uploadToEnkash}
                                    onChange={() => setUploadToEnkash(!uploadToEnkash)}
                                />}
                                label="Upload to Enkash"
                            />
                            <FormHelperText>
                                {enkashInformationString}
                            </FormHelperText>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            {enkashVendorSelector(!uploadToEnkash, vendorList, formData.enkashVendorId, handleVendorIdChange, handleVendorListSearchChange)}
                            <FormHelperText>
                                If a vendor isn't listed, please add them on Enkash, reload this page and try again.
                            </FormHelperText>
                        </Box>
                    </Box>
                    <Divider sx={{mt: 2, mb: 2}}></Divider>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        { formData.billFiles.length > 0 ?
                        <Box sx={{padding: 5, flex: 1}}>
                            Bill Files
                            {formData.billFiles.map((file, index) => (
                                <Typography key={index}>
                                    {index+1}. {file.name}: {` (${getHumanReadableFileSize(file.size)}) `}
                                </Typography>
                            ))}
                        </Box> : null}
                        { formData.additionalFiles.length > 0 ?
                        <Box sx={{padding: 5, flex: 1}}>
                            Additional Files
                            {formData.additionalFiles.map((file, index) => (
                                <Typography key={index}>
                                    {index+1}. {file.name}: {` (${getHumanReadableFileSize(file.size)}) `}
                                </Typography>
                            ))}
                        </Box> : null}
                    </Box>
                    <Box>
                        <Button onClick={uploadBillData} variant={"contained"} fullWidth>
                            {loading ? "Uploading ..." : "Save"}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    display: loading ? 'flex' : 'none', // Ensuring Backdrop is shown/hidden based on `loading`
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                open={loading}
                // onClick={handleClose}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </Box>
    )
}