import {generateUUID, notNull} from "../../utils/utils";
import {InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export const addBillUtil = (bills, setBills) => {
    const newId = generateUUID();
    const billsCopy = {...bills};
    billsCopy[newId] = { id: newId, name: "bill-name", entries: {}, description: '', account:null, batchNumber: null };
    setBills(billsCopy);
};

export const removeBillUtil = (id, bills, setBills) => {
    const billsCopy = {...bills};
    if (id in billsCopy) {
        delete billsCopy[id];
    }
    setBills(billsCopy);
}


export const getUserSelectionBox = (editMode, updatePaidBy, paidByUserId, allUsers, label) => {
    return <>
        <InputLabel sx={{mr: 2}} id="paid-by-select">{label}</InputLabel>
        <Select
            disabled={!editMode}
            onChange={updatePaidBy}
            labelId="paid-by-select"
            value={paidByUserId}
            inputProps={{
                sx: {
                    padding: 1,
                    height: "40px",
                }
            }}
        >
            {allUsers.map((user) => {
                return (
                    <MenuItem key={user.id} value={user.id}>
                        {user.username}
                    </MenuItem>
                )
            })}
        </Select>
    </>;
}


export const deleteBillUtils = (billId, entryId, bills, setBills) => {
    const billsCopy = {...bills};
    const entriesCopy = {...billsCopy[billId].entries };
    if (entryId in entriesCopy) {
        delete entriesCopy[entryId];
    }
    billsCopy[billId].entries = entriesCopy;
    setBills(billsCopy);
}

export const handleBillsFileChangeUtil = (files, billId, bills, setBills) => {
    const billsCopy = {...bills};
    const entriesCopy = {...billsCopy[billId].entries };
    Object.values(files).forEach((file) => {
        const entryId = generateUUID();
        entriesCopy[entryId] = {id: entryId, file: file};
    })
    billsCopy[billId].entries = entriesCopy;
    setBills(billsCopy);
}

export const removeAdditionalFileEntryUtil = (id, additionalFiles, setAdditionalFiles) => {
    const additionalFilesCopy = {...additionalFiles};
    if (id in additionalFilesCopy) {
        delete additionalFilesCopy[id];
        console.log("Removed ", id, " from ", additionalFilesCopy);
        setAdditionalFiles(additionalFilesCopy);
    }
}

export const handleAdditionalFilesChangeUtil = (files, additionalFiles, setAdditionalFiles) => {
    const additionalFilesCopy = {...additionalFiles};
    Object.values(files).forEach((file) => {
        const entryId = generateUUID();
        additionalFilesCopy[entryId] = {id: entryId, file: file};
    });
    setAdditionalFiles(additionalFilesCopy);
}

export const handleBillDataUpdateUtil = (billId, key, val, bills, setBills) => {
    const billsCopy = {...bills};
    if (billId in billsCopy) {
        billsCopy[billId][key] = val;
    }
    setBills(billsCopy);
}

export const appendBillsInFormData = (bills, fileFormsData) => {
    const billsData = [];
    Object.values(bills).forEach((bill, index) => {
        const entries = Object.values(bill.entries);
        if (entries.length > 0) {
            const key = "bill#" + index + "_" + bill.name;
            entries.forEach((entry, index) => {
                fileFormsData.append(key, entry.file);
            })
            let account_details = null;
            if (notNull(bill.account)) {
                account_details = JSON.stringify(bill.account);
            }
            billsData.push({
                name: bill.name,
                description: bill.description,
                account: account_details,
                batchNumber: bill.batchNumber,
                fileKey: key
            });
        }
    });
    fileFormsData.append("billsData", JSON.stringify(billsData));
}

export const appendAdditionalFilesInFormData = (additionalFiles, fileFormsData) => {
    Object.values(additionalFiles).forEach((file, index) => {
        fileFormsData.append("additionalFiles", file.file)
    })
}