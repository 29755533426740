import {Box, Button, CircularProgress, FormControl, MenuItem, Select, Typography} from "@mui/material";
import React, {useState} from "react";
import Divider from "@mui/material/Divider";
import FileUploadComponent from "../components/FileUploadComponent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {axiosInstance} from "../utils/axiosConfig";
import {UPLOAD_TRANSACTION_STATEMENTS} from "../utils/consts";
import {notifyError, notifySuccess} from "../utils/display.notifications";
import Backdrop from "@mui/material/Backdrop";

export const UploadTransactions = () => {
    const [transactionFiles, setTransactionFiles] = useState([]);
    const [selectedStatementType, setSelectedStatementType] = React.useState("icici_transactions");

    // Status flag
    const [uploading, setUploading] = React.useState(false);

    const statement_types = [
        {"id": "icici_transactions", name: "ICICI Transactions (DetailedStatements.psv)", allowed_files: ["*"]},
        {"id": "enkash_vendor_payments", name: "Enkash Vendor Payments (INVOICE_REPORT*.xlsx)", allowed_files: ["*"]},
        {"id": "enkash_rupay_card_statements", name: "Enkash Rupay Card Payments (EXPENSE_RECORD*.xls)", allowed_files: ["*"]},
        {"id": "enkash_virtual_card_transactions", name: "Enkash Virtual Card Payments (ENKASH_CARD_TXNS*.xlsx)", allowed_files: ["*"]},
    ];

    const uploadTransactionFiles = () => {
        const formData = new FormData();
        if (transactionFiles.length > 0) {
            formData.append("transactionFile", transactionFiles[0]);
        }
        formData.append("statement_type", selectedStatementType);
        setUploading(true);
        axiosInstance.post(UPLOAD_TRANSACTION_STATEMENTS, formData)
            .then((res) => {
                notifySuccess("uploadtransactions", "Transaction files uploaded succesfully");
            })
            .catch((err) => {
                console.log(err);
                notifyError("uploadtransactions", "Error in uploading transaction files");
            })
            .finally(() => {
                setUploading(false);
            })
    }

    const handleTransactionFilesChange = (e) => {
        setTransactionFiles(Array.from(e.target.files));
    }

    let allowed_files = [];
    statement_types.forEach((type) => {
        if (type.id === selectedStatementType) {
            allowed_files.push(type.allowed_files);
        }
    })

    return (
        <Box sx={{width: "100%", marginRight: "100px"}}>
           <Typography variant="h3">Upload Transactions</Typography>
           <Divider sx={{mt: 2, mb: 2}}></Divider>
           <FormControl sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
               <Select
                   value={selectedStatementType}
                   onChange={(e) => setSelectedStatementType(e.target.value)}>
                   {
                       statement_types.map((type) => (
                           <MenuItem key={type.id} value={type.id}>
                               {type.name}
                           </MenuItem>
                       ))
                   }
               </Select>
               <FileUploadComponent
                   text={"Attach transaction files"}
                   files={transactionFiles}
                   handleFileChange={handleTransactionFilesChange}
                   allowFiles={allowed_files.join(",")}
                   multipleAllowed={false}
                   showFiles={false}
               />
               <Button
                   disabled={transactionFiles.length === 0}
                   variant="contained"
                   onClick={uploadTransactionFiles}
                   sx={{
                       padding: "0 !important",
                       height: "37px",
                       width: "100px"
                   }}>
                   Upload
               </Button>
           </FormControl>
           <Divider sx={{mt: 2, mb: 2}}></Divider>
            <List>
                {
                    transactionFiles.map((file, index) => {
                        return <ListItem key={index}>{`${index+1}. ${file.name}`}</ListItem>
                    })
                }
            </List>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={uploading}
                // onClick={handleClose}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </Box>
    )
}