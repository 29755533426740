import axios from 'axios';
import Cookies from "js-cookie";

function getCSRFToken() {
    const cookieString = document.cookie;
    const cookieArray = cookieString.split('; ');
    const csrfCookie = cookieArray.find(cookie => cookie.startsWith('csrftoken='));
    if (csrfCookie) {
        return csrfCookie.split('=')[1];
    } else {
        return null;
    }
}

function getAccessToken() {
    console.log("Retrieving access token: ", Cookies.get('access_token'));
    return Cookies.get('access_token');
}

export let axiosInstance = axios.create({
    headers: {
        'Authorization': `Token ${getAccessToken()}`
    },
});

export let axiosMultiFormPostInstance = axios.create({
    headers: {
        'Authorization': `Token ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
    },
});

export const reloadAxiosInstance = () => {
    axiosInstance = axios.create({
        headers: {
            'Authorization': `Token ${getAccessToken()}`
        },
    });
    axiosMultiFormPostInstance = axios.create({
        headers: {
            'Authorization': `Token ${getAccessToken()}`,
            'Content-Type': 'multipart/form-data',
        },
    });
}