import {Box, Card, CardContent, IconButton, Link, Typography} from "@mui/material";
import {
    ContentCopy,
    CopyAll,
    CopyAllRounded,
    CopyAllTwoTone,
    FileCopyOutlined,
    FileOpen,
    FileOpenOutlined, PlayArrow, PlayCircle, SettingsOutlined
} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {copyToClipboard, isEmptyString, isNull} from "../../utils/utils";
import {notifyError, notifySuccess} from "../../utils/display.notifications";
import {axiosInstance} from "../../utils/axiosConfig";
import {GENERATE_IRN} from "../../utils/consts";
import React, {useState} from "react";

export const BankReconcillationDetails = (props) => {
    const {bankDetails, setUpdatedIRN, updatedIRN, disabled} = props;
    const {amount, debit_date, details} = bankDetails;
    const bankDataNotAvailable = isEmptyString(amount) || parseFloat(amount) === 0.0;

    const generateIRN = () => {
        axiosInstance.get(GENERATE_IRN)
            .then((response) => {
                setUpdatedIRN(response.data.irn);
                notifySuccess("irngen", "IRN Generated");
            })
            .catch((err) => {
                console.log("ERROR in generating IRN: ", err);
                notifyError("irngen", "Error generating IRN");
            })
    }

    let bankDetailsContent = (
        <Typography fontWeight={'bold'}>
            Bank details not yet linked.
        </Typography>
    );

    if (!bankDataNotAvailable) {
        bankDetailsContent = (
            <>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                    Bank Amount: {amount}/-
                </Typography>
                <Divider></Divider>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                    Bank Debit Date: {debit_date}
                </Typography>
                <Divider></Divider>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                    Details: {details}
                </Typography>
                <Divider></Divider>
            </>
        );
    }


    let irnContent = (
        <>
            <Typography fontSize={'14px'} fontWeight={'bold'}>
                IRN: {updatedIRN}
            </Typography>
            <ContentCopy onClick={() => copyToClipboard(updatedIRN)} sx={{fontSize: "16px", marginLeft: "5px", cursor: "pointer"}}></ContentCopy>
        </>
    );
    if (isEmptyString(updatedIRN)) {
        irnContent = (
            <>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                    Generate IRN
                </Typography>
                <IconButton onClick={generateIRN} disabled={disabled}>
                    <PlayCircle sx={{color: disabled ? "default":"#196F3D"}}></PlayCircle>
                </IconButton>
            </>
        )
    }

    return (
        <Card sx={{width:'100%', float: "center", padding: '10px', marginBottom: 2, backgroundColor: '#D6EAF8'}} >
            <CardContent sx={{padding: "0px !important"}}>
                {bankDetailsContent}
                <Box sx={{display: "flex", alignItems: "center"}}>
                    {irnContent}
                </Box>
            </CardContent>
        </Card>
    );
}