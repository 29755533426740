import React from 'react';
import { Divider, Typography, Box } from '@mui/material';

const ItalicTextDivider = ({ text }) => {
    return (
        <Box display="flex" alignItems="center" sx={{ marginTop: "5px" }}>
            <Box flexGrow={1}>
                <Divider />
            </Box>
            <Typography variant="body2" sx={{ mx: 2, fontStyle: 'italic', color: 'grey' }}>
                {text}
            </Typography>
            <Box flexGrow={1}>
                <Divider />
            </Box>
        </Box>
    );
};

export default ItalicTextDivider;
