import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField
} from '@mui/material';
import {handleApiError, isNull} from "../utils/utils";
import {axiosInstance} from "../utils/axiosConfig";
import {CREATE_VENDOR_API} from "../utils/consts";
import {notifySuccess} from "../utils/display.notifications";

const CreateVendor = ({ open, onClose }) => {
    const [vendorName, setVendorName] = useState('');
    const [vendorGST, setVendorGST] = useState(undefined);

    const handleCreateVendor = () => {
        const params = {
            vendorName: vendorName
        }

        if (!isNull(vendorGST)) {
            params["vendorGST"] = vendorGST;
        }

        axiosInstance.post(CREATE_VENDOR_API, params)
            .then(resp => {
                console.log(resp);
                notifySuccess("createVendor", "Vendor Created");
            })
            .catch((err) => {
                console.log(err);
                handleApiError("createVendor", err);
            })
            .finally(() => {
                setVendorName('');
                setVendorGST('');
                onClose();
            })
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Vendor</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the details for the new vendor.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Vendor name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={vendorName}
                    onChange={(e) => setVendorName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Vendor GST No. (Optional)"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={vendorGST}
                    onChange={(e) =>
                        setVendorGST(e.target.value ? e.target.value.toUpperCase() : e.target.value)
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCreateVendor} variant={"contained"} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateVendor;
