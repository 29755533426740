import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Box,
    Typography,
    Grid, CircularProgress, Link, Autocomplete,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import dayjs from 'dayjs';
import MultipleBillsComponent from './MultipleBillsComponent';
import FileUploadComponent from '../../components/FileUploadComponent';
import ItalicTextDivider from "../../components/ItalicTextDivider";
import "./styles.css";
import {AttachFile, Label, RemoveCircle} from "@mui/icons-material";
import {generateUUID, getUserFromLocalStorage, handleApiError, isEmptyString, isNull} from "../../utils/utils";
import Divider from "@mui/material/Divider";
import {axiosInstance, axiosMultiFormPostInstance} from "../../utils/axiosConfig";
import {notifyError, notifySuccess, notifyWarning} from "../../utils/display.notifications";
import {useNavigate, useParams} from "react-router-dom";
import {
    DELETE_FILE_ENTRY_API,
    DELETE_BILL_ENTRY_API, EDIT_PAYMENT_ENTRY_API,
    GET_PAYMENT_ENTRY_API, DELETE_PAYMENT_ENTRY_API
} from "../../utils/consts";
import {FilesListView} from "../../components/FilesListView";
import {
    addBillUtil, appendAdditionalFilesInFormData, appendBillsInFormData,
    deleteBillUtils, getUserSelectionBox, handleAdditionalFilesChangeUtil, handleBillDataUpdateUtil,
    handleBillsFileChangeUtil,
    removeAdditionalFileEntryUtil,
    removeBillUtil
} from "./payment_form_utils";
import {BankReconcillationDetails} from "./BankReconcillationDetails";
import ConfirmationModal from "../../components/ConfirmationModal";
import {
    downloadFile,
    fetchAllPaymentModesUtil,
    fetchAllUsersUtil,
    fetchInvoiceNumberList,
    fetchVendorsList
} from "../utils";
import CreateVendor from "../CreateVendorPopup";
import {CustomPopper} from "../../components/CustomPopper";

const EditPaymentForm = () => {

    const {id:paymentId} = useParams();
    const startId = generateUUID();
    const startingBill = {};
    startingBill[startId] = {id: startId, entries: {}};
    const [bills, setBills] = useState({});
    const [paymentModes, setPaymentModes] = useState([]);
    const [vendorList, setVendorList] = useState([])
    const [additionalFiles, setAdditionalFiles] = useState({});
    const [uploadedAdditionalFiles, setUploadedAdditionalFiles] = useState({});
    const [uploadedBills, setUploadedBills] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [zohoAmountDiff, setZohoAmountDiff] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [paidByUserId, setPaidByUserId] = useState(null);
    const [createdByUserId, setCreatedByUserId] = useState(null);
    const [vendorListSearchMeta, setVendorListSearchMeta] = useState({
        searchQuery: '',
        maxEntries: 100,
    });

    // Status flags
    const [savingDetails, setSavingDetails] = useState(false)
    const [loading, setLoading] = useState(false);
    const [paymentEntryDeletionModal, setPaymentEntryDeletionModal] = useState(false);
    const [billEntryDeletionModal, setBillEntryDeletionModal] = useState(false);
    const [additionalFileEntryDeletionModal, setAdditionalFileEntryDeletionModal] = useState(false);
    const [paymentIdToBeRemoved, setPaymentIdToBeRemoved] = useState(null);
    const [billIdToBeRemoved, setBillIdToBeRemoved] = useState(null);
    const [fileIdToBeRemoved, setFileIdToBeRemoved] = useState(null);
    const [updatedIRN, setUpdatedIRN] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [showCreateVendorPopup, setShowCreateVendorPopup] = useState(false);

    const navigate = useNavigate();

    // FormData
    const [formData, setFormData] = useState({
        paymentMode: '',
        paymentReferenceNumber: '',
        amount: '',
        description: '',
        billRequired: 'yes',
        vendor: null
    });

    const user = getUserFromLocalStorage();

    const fetchAllUsers = () => {
        fetchAllUsersUtil(setAllUsers);
    }

    const fetchPaymentModes = () => {
        fetchAllPaymentModesUtil(setPaymentModes)
    };

    const fetchPaymentData = (updateFormData=true) => {
        setLoading(true);
        const queryString = "?payment_id=" + paymentId;
        axiosInstance.get(GET_PAYMENT_ENTRY_API + queryString)
            .then(response => {
                console.log("PD: ", response);
                const payment_data = response.data.payment_data;
                if (updateFormData) {
                    setFormData({...formData, ...payment_data});
                    setBankDetails(payment_data.bank_details);
                    setUpdatedIRN(payment_data.irn);
                    setPaidByUserId(payment_data.paid_by);
                    setCreatedByUserId(payment_data.created_by)
                    setZohoAmountDiff({
                        bank_amount_sum: payment_data.bank_amount,
                        zoho_amount_sum: payment_data.zoho_amount_sum,
                        zoho_diff_exceeded: payment_data.zoho_diff_exceeded,
                    })
                }
                setUploadedAdditionalFiles(payment_data.additional_files);
                setUploadedBills(payment_data.bills);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchPaymentModes();
        fetchAllUsers();
        fetchPaymentData();
    }, []);

    useEffect(() => {
        fetchVendorsList(setVendorList, vendorListSearchMeta.searchQuery, vendorListSearchMeta.maxEntries);
    }, [vendorListSearchMeta]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Calling Submit button");

        if (isNull(paidByUserId)) {
            notifyError("createpayment", "Please select USER for paid by field.");
            return;
        }

        if (isEmptyString(formData.paymentMode)) {
            notifyError("createpayment", "Please select a payment mode.");
            return;
        }

        const fileFormsData = new FormData();
        const data = {
            paymentId: paymentId,
            paymentMode: formData.paymentMode,
            paymentReferenceNumber: formData.paymentReferenceNumber,
            amount: formData.amount,
            description: formData.description,
            paidBy: paidByUserId,
            billRequired: formData.billRequired
        };

        if (!isNull(updatedIRN)) {
            data['updatedIRN'] = updatedIRN;
        }

        if (!isNull(formData.vendor)) {
            data['vendorId'] = formData.vendor.id;
        }

        appendAdditionalFilesInFormData(additionalFiles, fileFormsData);
        appendBillsInFormData(bills, fileFormsData);

        Object.keys(data).forEach((key) => {
            fileFormsData.set(key, data[key]);
        });

        setSavingDetails(true);
        setLoading(true);
        axiosMultiFormPostInstance.post(EDIT_PAYMENT_ENTRY_API, fileFormsData)
            .then((response) => {
                if (response.status < 300) {
                    console.log(response);
                    notifySuccess("createpayment-success", "Payment recorded succesfully.");
                    if (response.data.bill_creation_failed) {
                        notifyWarning("createpayment-bill", "Bill Creation failed, please upload bill for added payment entry from edit tab.")
                    }
                    if (response.data.additional_files_upload_failed) {
                        notifyWarning("createpayment-additional-file", "Failed to upload additional files for added payment, please add additional files again.")
                    }
                    if (response.data.bill_files_upload_failed) {
                        notifyWarning("createpayment-bill-file", "Failed to upload bill files for added payment, please add additional files again.")
                    }
                    setAdditionalFiles({});
                    setBills({});
                    fetchPaymentData();
                } else {
                    notifyError("createpayment-error", "Failed to create payment entry, please retry.");
                }
            })
            .catch((err) => {
                const errNotificationId = "editpayment-error";
                console.error(err);
                handleApiError(errNotificationId, err);
            })
            .finally(() => {
                setSavingDetails(false);
                setEditMode(false);
                setLoading(false);
            })
    };

    const addBill = () => {
        addBillUtil(bills, setBills);
    };

    const removeBill = (id) => {
        removeBillUtil(id, bills, setBills);
    }

    const handleBillNameUpdate = (billId, name) => {
        handleBillDataUpdateUtil(billId, "name", name, bills, setBills);
    }

    const handleAccountChange = (billId, account_details) => {
        handleBillDataUpdateUtil(billId, "account", account_details, bills, setBills);
    }

    const handleBillDescriptionUpdate = (billId, description) => {
        handleBillDataUpdateUtil(billId, "description", description, bills, setBills);
    }

    const deleteBillEntry = (billId, entryId) => {
        deleteBillUtils(billId, entryId, bills, setBills);
    }

    const handleBillsFileChange = (files, billId) => {
        handleBillsFileChangeUtil(files, billId, bills, setBills);
    }

    const removeAdditionalFileEntry = (id) => {
        removeAdditionalFileEntryUtil(id, additionalFiles, setAdditionalFiles);
    }

    const handleAdditionalFilesChange = (files) => {
        handleAdditionalFilesChangeUtil(files, additionalFiles, setAdditionalFiles);
    }

    const updatePaidBy = (event) => {
        setPaidByUserId(event.target.value);
    }

    const removeUploadedAdditionalFileEntry = () => {
        const id = fileIdToBeRemoved;
        if (isNull(id)) {
            return;
        }
        // DB call to remove file.
        const query = {
            fileId: id,
        }
        axiosInstance.delete(DELETE_FILE_ENTRY_API, {params: query})
            .then((response) => {
                console.log(response);
                notifySuccess("editpayment", response.data.msg);
                fetchPaymentData(false);
            })
            .catch(err => {
                const errNotificationId = "editpayment-error";
                console.error(err);
                handleApiError(errNotificationId, err);
            })
            .finally(() => {
                setAdditionalFileEntryDeletionModal(false);
            });
    }

    const deleteSavedPaymentEntry = () => {
        if (isNull(paymentIdToBeRemoved)) {
            notifyError("paymentedit", "Invalid payment id, failed to remove");
            return;
        }

        const query = {
            paymentId: paymentIdToBeRemoved
        }
        axiosInstance.delete(DELETE_PAYMENT_ENTRY_API, {params: query})
            .then((response) => {
                console.log(response);
                notifySuccess("paymentedit", "Deleted payment details");
                setTimeout(() => {
                    navigate("/");
                }, 300);
            })
            .catch(err => {
                const errNotificationId = "editpayment-error";
                console.error(err);
                handleApiError(errNotificationId, err);
            })
            .finally(() => {
                setPaymentEntryDeletionModal(false);
                setPaymentIdToBeRemoved(null);
            });
    }


    const deleteUploadedBillEntry = () => {
        const billId = billIdToBeRemoved;

        if (isNull(billId)) {
            return;
        }

        const query = {
            billId: billId,
            paymentId: paymentId
        }
        axiosInstance.delete(DELETE_BILL_ENTRY_API, {params: query})
            .then((response) => {
                console.log(response);
                notifySuccess("editpayment", response.data.msg);
                fetchPaymentData(false);
            })
            .catch(err => {
                const errNotificationId = "editpayment-error";
                console.error(err);
                handleApiError(errNotificationId, err);
            })
            .finally(() => {
                setBillEntryDeletionModal(false);
            })
    }

    let uploadedAdditionalFilesView = null, additionalFilesView = null;
    if (Object.keys(uploadedAdditionalFiles).length > 0) {
        uploadedAdditionalFilesView = (<>
            <ItalicTextDivider text={"Uploaded"}/>
            <div style={{overflowY: 'scroll', maxHeight: "200px"}}>
                <FilesListView
                    removeHandler={(id) => {
                        setFileIdToBeRemoved(id);
                        setAdditionalFileEntryDeletionModal(true);
                    }}
                    files={Object.values(uploadedAdditionalFiles)}
                    uploaded={true}
                    showDownloadIcon={true}
                    handleFileDownload={(fileId) => downloadFile(fileId)}
                />
            </div>
        </>);
    }

    if (Object.keys(additionalFiles).length > 0) {
        additionalFilesView = (
            <>
                <ItalicTextDivider text={"Newly Added"}/>
                <div style={{overflowY: 'scroll', maxHeight: "170px"}}>
                    <FilesListView
                        removeHandler={removeAdditionalFileEntry}
                        files={Object.values(additionalFiles)}
                    />
                </div>
            </>
        )
    }

    const handleVendorListSearchChange = (e) => {
        setVendorListSearchMeta({
            ...vendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const handleVendorSelection = (vendor) => {
        setFormData({
            ...formData,
            vendor: vendor
        })
    }

    const transactionIdLabel = formData.paymentMode ?
        paymentModes.find(mode => mode.key === formData.paymentMode)?.ref_matcher :
        "First choose a Payment Method";

    let canDeletePaymentEntry = user.is_staff;
    console.log("USER: ", user);
    if (!isNull(createdByUserId) && user.id === JSON.stringify(createdByUserId)) {
        canDeletePaymentEntry = true;
    }

    return (
        <Box
            component="form"
            sx={{width: "100%", marginRight: "100px", height: "100vh", overflowY: "hidden"}}
        >
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Typography variant='h5'>{editMode ? "Edit Payment Details" : "Payment details"}</Typography>
                <Box sx={{
                    display: "flex", justifyContent: "center", alignItems: "center"
                }}>
                    {
                        canDeletePaymentEntry ? <Button
                            disabled={editMode ? !canDeletePaymentEntry : true}
                            variant="contained"
                            color="error"
                            sx={{mr: 2}}
                            onClick={() => {
                                setPaymentIdToBeRemoved(paymentId);
                                setPaymentEntryDeletionModal(true);
                            }}
                        >
                            Delete Payment Entry
                        </Button> : null
                    }
                    {
                        !editMode ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditMode(true);
                                }}
                            >
                                Start Editing
                            </Button>
                        ) : (
                            <Button
                                disabled={savingDetails || !editMode}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {savingDetails ? <CircularProgress title={"Saving..."}/> : "Update payment"}
                            </Button>
                        )
                    }
                </Box>
            </Box>
            <Divider style={{marginTop: "10px", marginBottom: "10px"}}></Divider>
            <Grid container spacing={2} sx={{height: "800px", overflowY: "scroll", maxHeight: "800px"}}>
                <Grid item xs={12} sm={4}>
                    <div className={"payment-details-box card-box-shadow"} style={{padding: 16}}>
                        <Box display={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Typography style={{marginBottom: 10}} variant='h6'>Payment Details</Typography>
                            <Box style={{display: "flex", alignItems: "center"}}>
                                {getUserSelectionBox(editMode, updatePaidBy, paidByUserId, allUsers, "Paid By")}
                            </Box>
                        </Box>
                        <BankReconcillationDetails
                            disabled={!editMode}
                            updatedIRN={updatedIRN} bankDetails={bankDetails} setUpdatedIRN={setUpdatedIRN}/>
                        <FormControl fullWidth>
                            <InputLabel id="payment-method-label">Payment Method</InputLabel>
                            <Select
                                disabled={!editMode}
                                labelId="payment-method-label"
                                name="paymentMode"
                                value={formData.paymentMode}
                                onChange={handleChange}
                                label="Payment Method"
                                inputProps={{
                                    sx: {padding: "8px !important"}
                                }}
                            >
                                {paymentModes.map((mode) => (
                                    <MenuItem key={mode.key} value={mode.key}>
                                        {mode.key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography style={{marginTop: 23}} variant='caption'>
                            {formData.paymentMode ? `Find "${transactionIdLabel}" number from Bank/Transaction statement.` : transactionIdLabel}
                        </Typography>
                        <TextField
                            disabled={!formData.paymentMode || !editMode}
                            label={transactionIdLabel}
                            name="paymentReferenceNumber"
                            value={formData.paymentReferenceNumber}
                            onChange={handleChange}
                            fullWidth
                            sx={{mt: 2}}
                            style={{marginTop: "5px"}}
                            inputProps={{
                                sx: {padding: "8px !important"}
                            }}
                        />
                        <TextField
                            disabled={!editMode}
                            label="Amount"
                            name="amount"
                            type={"number"}
                            value={formData.amount}
                            onChange={handleChange}
                            fullWidth
                            sx={{mt: 2, mb: 2}}
                            inputProps={{
                                sx: {padding: "8px !important"}
                            }}
                        />

                        <FormControl fullWidth>
                            <InputLabel id="bill-required">Bill Required ? (e.g. Porter/Google ads wallet recharge do
                                not have bill)</InputLabel>
                            <Select
                                disabled={!editMode}
                                fullWidth
                                labelId="bill-required"
                                label="Bill Required ? (e.g. Porter/Google ads wallet recharge do not have bill)"
                                name="billRequired"
                                value={formData.billRequired}
                                onChange={handleChange}
                                inputProps={{
                                    sx: {padding: "8px !important"}
                                }}
                            >
                                <MenuItem key={'yes'} value={'yes'}>Yes</MenuItem>
                                <MenuItem key={'no'} value={'no'}>No</MenuItem>
                            </Select>
                        </FormControl>

                        <Autocomplete
                            disabled={!editMode}
                            options={vendorList}
                            value={formData.vendor}
                            getOptionLabel={(option) => option.name + " - " + option.gst}
                            onChange={(event, val) => handleVendorSelection(val)}
                            inputProps={{
                                sx: {padding: "8px !important"}
                            }}
                            size="small"
                            sx={{mt: 1}}
                            renderInput={(params) =>
                                <TextField {...params} label="Select Vendor" variant="outlined"
                                           onChange={handleVendorListSearchChange}/>
                            }
                            PopperComponent={CustomPopper}
                            noOptionsText={(
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <Typography>No such vendor found.</Typography>
                                    <Button color={"secondary"} onClick={() => setShowCreateVendorPopup(true)}>Create
                                        New Vendor</Button>
                                </div>)}
                        />


                        <TextField
                            disabled={!editMode}
                            fullWidth
                            multiline
                            label="Description-Fill in Vendor Name, Invoice No. etc (Optional)"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            minRows={2}
                            sx={{mt: 2}}
                        />
                        <ItalicTextDivider text={"Additional files"}/>
                        {uploadedAdditionalFilesView}
                        {additionalFilesView}
                        <FileUploadComponent
                            disabled={!editMode}
                            buttonStyles={{
                                variant: "text", color: 'secondary',
                                sx: {marginTop: '15px'},
                                startIcon: <AttachFile/>,
                            }}
                            text={"Attach More Additional Files"}
                            index={0}
                            files={additionalFiles}
                            showFiles={false}
                            handleFileChange={e => handleAdditionalFilesChange(Array.from(e.target.files))}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={8} style={{display: 'flex', flexDirection: 'column'}}>
                    <MultipleBillsComponent
                        disabled={!editMode}
                        handleBillNameUpdate={handleBillNameUpdate}
                        handleBillDescriptionUpdate={handleBillDescriptionUpdate}
                        bills={bills}
                        addBill={addBill}
                        removeBill={removeBill}
                        handleAccountChange={handleAccountChange}
                        handleBillsFileChange={handleBillsFileChange}
                        deleteBillEntry={deleteBillEntry}
                        uploadedBills={uploadedBills}
                        zohoAmountDiff={zohoAmountDiff}
                        deleteUploadedBill={(billId) => {
                            setBillIdToBeRemoved(billId);
                            setBillEntryDeletionModal(true);
                        }}
                    />
                </Grid>
            </Grid>
            <ConfirmationModal
                open={paymentEntryDeletionModal}
                confirmationText={"Are you sure you want to delete payment details?"}
                onConfirm={deleteSavedPaymentEntry}
                onClose={() => {
                    setBillIdToBeRemoved(null);
                    setBillEntryDeletionModal(false);
                }}/>
            <ConfirmationModal
                open={billEntryDeletionModal}
                confirmationText={"Are you sure you want to remove this bill from payment ?"}
                onConfirm={deleteUploadedBillEntry}
                onClose={() => {
                    setBillIdToBeRemoved(null);
                    setBillEntryDeletionModal(false);
                }}/>
            <ConfirmationModal
                open={additionalFileEntryDeletionModal}
                confirmationText={"Are you sure you want to remove additional file, this will delete entry from DB?"}
                onConfirm={removeUploadedAdditionalFileEntry}
                onClose={() => {
                    setFileIdToBeRemoved(null);
                    setAdditionalFileEntryDeletionModal(false);
                }}/>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1}}
                open={loading}
                // onClick={handleClose}
            >
                <CircularProgress color="primary"/>
            </Backdrop>
            <CreateVendor open={showCreateVendorPopup} onClose={() => setShowCreateVendorPopup(false)}/>
        </Box>
    );
};

export default EditPaymentForm;