// authActions.js
import axios from 'axios';
import {LOGIN_SUCCESS, LOGOUT_SUCCESS, SET_ERROR} from "../constants/login.constant";
import Cookies from 'js-cookie';
import toast from "react-hot-toast";
import {GOOGLE_AUTH_API} from "../../utils/consts";
import {getUserFromLocalStorage} from "../../utils/utils";

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const setError = (error) => ({
    type: SET_ERROR,
    payload: error,
});

export const googleLogin = (code) => async (dispatch) => {
    try {
        const res = await axios.get(`${GOOGLE_AUTH_API}?code=${code}`);
        const {first_name, last_name, email, username, id, is_staff} = res.data.user;
        const picture = res.data.picture;
        const {access_token} = res.data;
        localStorage.setItem('first_name', first_name);
        localStorage.setItem('last_name', last_name);
        localStorage.setItem('email', email);
        localStorage.setItem('username', username);
        localStorage.setItem('picture', res.data.picture)
        localStorage.setItem('user_id', id);
        localStorage.setItem('is_staff', is_staff);

        Cookies.set("access_token", access_token);
        toast.success('Logged in successfully as ' + first_name + " " + last_name);
        dispatch(loginSuccess({first_name, last_name, email, username, picture}));
    } catch (err) {
        dispatch(setError(err.message));
    }
};

export const loadUser = () => async (dispatch) => {
    try {
        // Retrieve tokens from cookies/localStorage
        const accessToken = Cookies.get('access_token') || localStorage.getItem('access_token');

        if (!accessToken) {
            return;
        }

        const user = getUserFromLocalStorage();

        dispatch({
            type: LOGIN_SUCCESS,
            payload: user
        });
    } catch (error) {
        dispatch({type: SET_ERROR});
    }
};