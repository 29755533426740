import {notifyError, notifySuccess} from "./display.notifications";

export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export const isNull = value => value === undefined || value === null;

export const isEmptyString = value => value === null || value === undefined || value === "";

export const notNull = value => !isNull(value);

export const getHumanReadableFileSize = (bytes, si = true, roundTo = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    let u = -1;
    const r = 10 ** roundTo;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(roundTo) + ' ' + units[u];
}
export const copyToClipboard = (text) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(
            () => {
                notifySuccess("copyclip", "Copied to clipboard!");
            },
            (err) => {
                console.error('Failed to copy text to clipboard:', err);
                notifyError("copyclip", "Failed to copy to clipboard");
            }
        );
    } else {
        // Fallback for older browsers
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            notifySuccess("copyclip", "Copied to clipboard!");
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
            notifyError("copyclip", "Failed to copy to clipboard");
        }
        document.body.removeChild(textArea);
    }
};

// Centralized error handler
export const handleApiError = (notificationId, error) => {
    if (error.response) {
        const { status, data } = error.response;
        switch (status) {
            case 400:
                notifyError(notificationId, data.msg || "Bad request. Please check your input.");
                break
            case 401:
                notifyError(notificationId, "Unauthorized. Please log in.");
                break
            case 403:
                notifyError(notificationId, "Forbidden. You don't have permission to perform this operation.");
                break
            case 404:
                notifyError(notificationId, "Resource not found.");
                break
            case 500:
                notifyError(notificationId, "Server error. Please try again later.");
                break
            default:
                notifyError(notificationId, "An unknown error occurred.");
                break
        }
    } else {
        notifyError(notificationId, "Network connection error, please check your network.");
    }
};

export const getUserFromLocalStorage = () => {
    const id = localStorage.getItem('user_id');
    const first_name = localStorage.getItem('first_name');
    const last_name = localStorage.getItem('last_name');
    const email = localStorage.getItem('email');
    const username = localStorage.getItem('username');
    const picture = localStorage.getItem('picture');
    const is_staff = JSON.parse(localStorage.getItem('is_staff'));

    if (first_name && last_name && email && username && picture) {
        return {
            id,
            first_name,
            last_name,
            email,
            username,
            picture,
            is_staff
        };
    } else {
        return null;
    }
};


export const COLOR_MAP = {
    "y": "#F7DC6F",
    "b": "#85C1E9"
}