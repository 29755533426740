import {Box, IconButton, Link, Tooltip, Typography} from "@mui/material";
import {CloudDone, DeleteTwoTone, FileDownloadOutlined} from "@mui/icons-material";
import React from "react";
import "./styles.css";
import {getHumanReadableFileSize} from "../utils/utils";
import {downloadFile} from "../pages/utils";

export const FilesListView = (props) => {
    const { files, removeHandler, uploaded, showRemoveIcon=true,
            showCloudIcon=true, showDownloadIcon=true } = props;
    return (
        <>
            {
                files.map((file_obj, index) => {
                    let id = index;
                    let file = file_obj;
                    if ("file" in file_obj) {
                        id = file_obj.id;
                        file = file_obj.file;
                    }

                    return (
                        <Box key={id}
                             style={{
                                 display: 'flex', justifyContent: 'space-between', padding: "5px",
                                 maxHeight: "30px"
                             }}>
                            <Tooltip title={file.name}>
                                <Box sx={{display: "flex", alignItems: "center"}}>
                                    <Link
                                        onClick={() => downloadFile(id)}
                                        href={"#"}
                                        className={uploaded ? "active" : "disabled-link"}
                                    >
                                        {file.name.length > 10 ? file.name.substring(0, 10) : file.name}
                                    </Link>
                                    <Typography style={{marginLeft: "10px", fontStyle: "italic", color: "grey"}}>
                                        ({getHumanReadableFileSize(file.size)})
                                    </Typography>
                                </Box>
                            </Tooltip>
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                {
                                    uploaded && showDownloadIcon &&
                                    (<Tooltip sx={{cursor: "pointer"}} title={"Download File"}>
                                        <FileDownloadOutlined onClick={() => downloadFile(id)}>

                                        </FileDownloadOutlined>
                                    </Tooltip>)
                                }
                                {(uploaded && showCloudIcon) && (
                                    <Tooltip title={"Saved in DB"}>
                                        <CloudDone sx={{color: "#1D8348"}}></CloudDone>
                                    </Tooltip>
                                )}
                                {showRemoveIcon && (
                                    <Tooltip title={"Delete from DB"}>
                                        <IconButton color={uploaded ? "error" : "default"} onClick={() => removeHandler(id)}>
                                            <DeleteTwoTone></DeleteTwoTone>
                                        </IconButton>
                                    </Tooltip>)
                                }
                            </Box>

                        </Box>
                    )
                })
            }
        </>
    );
}