import {Alert, Button, Fade, IconButton} from "@mui/material";
import React from "react";
import {CloseRounded} from "@mui/icons-material";
import {notNull} from "./utils";
import {clearNotification, pushNotification} from "../redux/actions/global.action";
import store from "../helper/store";

const dispatchNotification = (notification) => {
    store.dispatch(pushNotification(notification));
}
export const closeNotification = (id) => store.dispatch(clearNotification(id));

/**
 *
 * @param id {string} - unique id for the notification
 * @param message {string} - message to display
 * @param severity {string} - severity of the notification
 * @param showClose {boolean} - show close button
 * @param autoHideDuration {int} in ms (default 5000) - set to 0 to disable auto hide
 * @param showReload {boolean} - show reload button
 * @param anchorOrigin {object} vertical and horizontal position of the notification
 * @param custom_icon custom icon to be displayed - false to disable icon
 */
const buildNotification = (id, message, severity, showClose, autoHideDuration, showReload = false, anchorOrigin, custom_icon) => {

    const snackBarContent = (notNull(custom_icon) ?
        <Alert severity={severity} icon={custom_icon} action={showClose ?
            <IconButton color={severity} onClick={() => closeNotification(id)}>
                <CloseRounded/>
            </IconButton> : showReload ?
                <Button color={severity} onClick={() => window.location.reload()} variant="outlined" size="small">
                    Reload
                </Button> : null
        }>
            {message}
        </Alert> :
        <Alert severity={severity} action={showClose ?
            <IconButton color={severity} onClick={() => closeNotification(id)}>
                <CloseRounded/>
            </IconButton> : showReload ?
                <Button color={severity} onClick={() => window.location.reload()} variant="outlined" size="small">
                    Reload
                </Button> : null
        }>
            {message}
        </Alert>);
    return {
        id: id,
        key: id,
        style: {mt: 4},
        TransitionComponent: Fade,
        anchorOrigin: anchorOrigin || {vertical: "top", horizontal: "right"},
        autoHideDuration: notNull(autoHideDuration) ? (autoHideDuration > 0 ? autoHideDuration : undefined) : 5000,
        onClose: (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            closeNotification(id)
        },
        content: snackBarContent
    };
}

/**
 *
 * @param id {string} - unique id for the notification
 * @param message {string} - message to display
 * @param showClose {boolean} - show close button
 * @param autoHideDuration {int} in ms (default 5000) - set to 0 to disable auto hide
 * @param showReload {boolean} - show reload button
 * @param anchorOrigin {object} vertical and horizontal position of the notification
 * @param custom_icon custom icon to be displayed - false to disable icon
 */
export const notifySuccess = (id, message, showClose, autoHideDuration, showReload, anchorOrigin, custom_icon) =>
    dispatchNotification(buildNotification(id, message, "success", showClose, autoHideDuration, showReload, anchorOrigin, custom_icon));

/**
 *
 * @param id {string} - unique id for the notification
 * @param message {string} - message to display
 * @param showClose {boolean} - show close button
 * @param autoHideDuration {int} in ms (default 5000) - set to 0 to disable auto hide
 * @param showReload {boolean} - show reload button
 * @param anchorOrigin {object} vertical and horizontal position of the notification
 * @param custom_icon custom icon to be displayed - false to disable icon
 */
export const notifyInfo = (id, message, showClose, autoHideDuration, showReload, anchorOrigin, custom_icon) =>
    dispatchNotification(buildNotification(id, message, "info", showClose, autoHideDuration, showReload, anchorOrigin, custom_icon));

/**
 *
 * @param id {string} - unique id for the notification
 * @param message {string} - message to display
 * @param showClose {boolean} - show close button
 * @param autoHideDuration {int} in ms (default 5000) - set to 0 to disable auto hide
 * @param showReload {boolean} - show reload button
 * @param anchorOrigin {object} vertical and horizontal position of the notification
 * @param custom_icon custom icon to be displayed - false to disable icon
 */
export const notifyWarning = (id, message, showClose, autoHideDuration, showReload, anchorOrigin, custom_icon) =>
    dispatchNotification(buildNotification(id, message, "warning", showClose, autoHideDuration, showReload, anchorOrigin, custom_icon));

/**
 *
 * @param id {string} - unique id for the notification
 * @param message {string} - message to display
 * @param showClose {boolean} - show close button
 * @param autoHideDuration {int} in ms (default 5000) - set to 0 to disable auto hide
 * @param showReload {boolean} - show reload button
 * @param anchorOrigin {object} vertical and horizontal position of the notification
 * @param custom_icon custom icon to be displayed - false to disable icon
 */
export const notifyError = (id, message, showClose, autoHideDuration, showReload, anchorOrigin, custom_icon) =>
    dispatchNotification(buildNotification(id, message, "error", showClose, autoHideDuration, showReload, anchorOrigin, custom_icon));
