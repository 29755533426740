// NotificationProvider.js
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {isNull} from "../utils/utils";
import {useSnackbar} from "notistack";

const NotificationProvider = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.globalState.notifications);
    const { enqueueSnackbar, closeSnackbar: notistackCloseSnackbar } = useSnackbar();
    useEffect(() => {
        notifications.forEach((notification) => {
            console.log(notification);
            if (!isNull(notification)) {
                enqueueSnackbar(notification.key, {
                    key: notification.key,
                    anchorOrigin: notification.anchorOrigin,
                    autoHideDuration: notification.autoHideDuration,
                    onClose: notification.onClose,
                    TransitionComponent: notification.TransitionComponent,
                    style: notification.style,
                    content: notification.content,
                })
            }
        })
    }, [notifications, enqueueSnackbar, notistackCloseSnackbar, dispatch]);
    return null;
};

export default NotificationProvider;
