import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {GET_PAYMENT_DETAILS} from "../utils/consts";
import {axiosInstance} from "../utils/axiosConfig";
import {fetchAllPaymentModesUtil, fetchAllUsersUtil, getTableCellContent, handleDownload} from "./utils";
import "./page_styles.css";
import {DateRangeSelector} from "../components/DateRangeSelector";
import {COLOR_MAP, isNull, notNull} from "../utils/utils";
import {notifyError} from "../utils/display.notifications";
import {useNavigate} from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import {ClearIcon} from "@mui/x-date-pickers";
import {DatasetOutlined} from "@mui/icons-material";
import Divider from "@mui/material/Divider";

const PaymentDetailsTable = () => {
    const [payments, setPayments] = useState([]);
    const [columns, setColumns] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [paymentModes, setPaymentModes] = useState([]);
    const [loading, setLoading] = useState(true);

    const userId = localStorage.getItem("user_id");
    const navigate = useNavigate();
    const [searchQueryDraft, setSearchQueryDraft] = useState(null);

    const [tableViewMetas, setTableViewMetas] = useState(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('searchQuery')) {
            setSearchQueryDraft(params.get('searchQuery'))
        }

        return {
            pageNumber: parseInt(params.get('pageNumber')) || 0,
            pageSize: parseInt(params.get('pageSize')) || 25,
            sortBy: params.get('sortBy') || 'created_at',
            sortOrder: params.get('sortOrder') || 'desc',
            paidByFilter: params.get('paidByFilter') || userId || "all",
            paymentModeFilter: params.get('paymentModeFilter') || 'all',
            linkedToBankFilter: params.get('linkedToBankFilter') || 'all',
            billLinkedFilter: params.get('billLinkedFilter') || 'all',
            billRequiredFilter: params.get('billRequiredFilter') || 'all',
            billDueFilter: params.get('billDueFilter') || 'all',
            createdAtStartDate: params.get('createdAtStartDate') || null,
            createdAtEndDate: params.get('createdAtEndDate') || null,
            bankDebitStartDate: params.get('bankDebitStartDate') || null,
            bankDebitEndDate: params.get('bankDebitEndDate') || null,
            searchQuery: params.get('searchQuery') || null
        };
    });

    const fetchAllUsers = () => {
        fetchAllUsersUtil(setAllUsers);
    }

    const fetchPaymentModes = () => {
        fetchAllPaymentModesUtil(setPaymentModes)
    };

    const fetchPayments = (useDataForCSV=false) => {
        const {
            pageNumber, pageSize, totalEntries, sortBy, sortOrder, billRequiredFilter, billDueFilter,
            paidByFilter, paymentModeFilter, billLinkedFilter, linkedToBankFilter, searchQuery,
            createdAtStartDate, createdAtEndDate, bankDebitStartDate, bankDebitEndDate
        } = tableViewMetas;
        let queryString = `?page_number=${pageNumber + 1}&page_size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}` +
            `&paidByFilter=${paidByFilter}&paymentModeFilter=${paymentModeFilter}` +
            `&linkedToBankFilter=${linkedToBankFilter}&billLinkedFilter=${billLinkedFilter}` +
            `&billRequiredFilter=${billRequiredFilter}&billDueFilter=${billDueFilter}&paginate=${!useDataForCSV}`;

        if (!isNull(createdAtStartDate) && !isNull(createdAtEndDate)) {
            queryString += `&createdAtStartDate=${createdAtStartDate}&createdAtEndDate=${createdAtEndDate}`;
        }

        if (!isNull(bankDebitStartDate) && !isNull(bankDebitEndDate)) {
            queryString += `&bankDebitStartDate=${bankDebitStartDate}&bankDebitEndDate=${bankDebitEndDate}`;
        }

        if (!isNull(searchQuery)) {
            queryString += `&searchQuery=${searchQuery}`;
        }

        setLoading(true);
        return axiosInstance.get(GET_PAYMENT_DETAILS + queryString)
            .then(resp => {
                if (useDataForCSV) {
                    return resp.data;
                }
                setPayments(resp.data.payment_details);
                setColumns(resp.data.columns);
                if (resp.data.page.total !== totalEntries) {
                    setTableViewMetas({
                        ...tableViewMetas,
                        totalEntries: resp.data.page.total
                    });
                }
                if (resp.data.page.current - 1 !== pageNumber) {
                    setTableViewMetas({
                        ...tableViewMetas,
                        pageNumber: resp.data.page.current - 1
                    });
                }
            })
            .catch(err => {
                console.log(err);
                notifyError("paymentstable", "Something went wrong in getting payment details, please retry");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateURLParams = (newParams) => {
        const url = new URL(window.location.href);
        Object.keys(newParams).forEach(key => {
            if ((notNull(newParams[key]) && newParams[key] !== "all" && !key.includes("totalEntries")) || key === "paidByFilter") {
                url.searchParams.set(key, newParams[key]);
            } else {
                url.searchParams.delete(key);
            }
        });
        window.history.pushState({}, '', url.toString());
    };


    useEffect(() => {
        fetchAllUsers();
        fetchPaymentModes();
    }, []);

    useEffect(() => {
        fetchPayments();
        updateURLParams(tableViewMetas);
    }, [tableViewMetas]);

    const handleChangePageSize = (e) => {
        const size = parseInt(e.target.value, 10);
        setTableViewMetas({
            ...tableViewMetas,
            pageSize: size,
        });
    }

    const handlePageChange = (e, newPage) => {
        setTableViewMetas({
            ...tableViewMetas,
            pageNumber: newPage,
        });
    }

    const handleSortByChange = (newSortBy) => {
        const isAsc = newSortBy === tableViewMetas.sortBy && tableViewMetas.sortOrder === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc';
        setTableViewMetas({
            ...tableViewMetas,
            sortOrder: sortOrder,
            sortBy: newSortBy,
        });
    }

    const handleChangePaidByFilter = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            paidByFilter: e.target.value,
        });
    }

    const handlePaymentModeFilterChange = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            paymentModeFilter: e.target.value,
        });
    }

    const handleBankLinkFilterChange = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            linkedToBankFilter: e.target.value,
        });
    }

    const handleBillRequiredFilterChange = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            billRequiredFilter: e.target.value,
        });
    }

    const handleBillDueFilterChange = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            billDueFilter: e.target.value,
        });
    }

    const handleBillLinkFilterChange = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            billLinkedFilter: e.target.value,
        });
    }

    const parseDateString = (dateString) => {
        if (isNull(dateString))
            return null
        const [day, month, year] = dateString.split('/').map(Number);

        return new Date(year, month - 1, day);
    }

    const handleCreatedAtDateRangeChange = (selection) => {
        setTableViewMetas({
            ...tableViewMetas,
            createdAtStartDate: selection.startDate ? selection.startDate.toLocaleDateString('en-IN') : null,
            createdAtEndDate: selection.endDate ? selection.endDate.toLocaleDateString('en-IN') : null,
        })
    }

    const handleBankDebitDateRangeChange = (selection) => {
        setTableViewMetas({
            ...tableViewMetas,
            bankDebitStartDate: selection.startDate ? selection.startDate.toLocaleDateString('en-IN') : null,
            bankDebitEndDate: selection.endDate ? selection.endDate.toLocaleDateString('en-IN') : null,
        })
    }

    const handleSearchQueryUpdate = (e) => {
        setSearchQueryDraft(e.target.value);
    }

    const handleSearchSubmit = (e) => {
        setTableViewMetas({
            ...tableViewMetas,
            searchQuery: searchQueryDraft
        })
    }


    const tablesPaginatedObj = (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableViewMetas.totalEntries}
            rowsPerPage={tableViewMetas.pageSize}
            page={tableViewMetas.pageNumber}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangePageSize}
        />
    );

    let tableDataRows = (
        <div sx={{
            display: 'flex',
            width: "300px",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center"
        }}>
            <DatasetOutlined/>
            <Typography variant="h6">
                No Data found.
            </Typography>
        </div>
    );
    if (payments.length > 0) {
        tableDataRows = payments.map((payment, index) => (
            <TableRow
                key={index}
                className={"tableRow"}
                onDoubleClick={() => {
                    navigate(`/payment/edit/${payment.id}`);
                }}>
                {columns.map((column, index) => {
                    return (
                        <TableCell
                            key={index}
                            sx={{
                                padding: 0, paddingLeft: 0.5, paddingBottom: "1px",
                                border: "1px solid grey", maxWidth: column.width,
                                backgroundColor: column.color_group ? COLOR_MAP[column.color_group] : "default"
                            }}>
                            {getTableCellContent(payment, column, index)}
                        </TableCell>
                    );
                })}
            </TableRow>
        ));
    }

    return (
        <Box style={{overflowX: "scroll"}}>
            <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h1>Payments ({tableViewMetas.totalEntries})</h1>
                <Box
                    sx={{ml: 15, display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%"}}>
                    <TextField
                        onChange={handleSearchQueryUpdate}
                        placeholder={"Search in transaction data"}
                        value={searchQueryDraft}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchQueryDraft && (
                                        <IconButton onClick={() => {
                                            setSearchQueryDraft('');
                                        }} edge="end">
                                            <ClearIcon/>
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                handleSearchSubmit(e);
                            }
                        }}
                        fullWidth>
                    </TextField>
                    <Button
                        onClick={handleSearchSubmit}
                        variant="contained" color="primary" sx={{ml: 3}}>
                        Search
                    </Button>
                    <Button onClick={() => {
                        handleDownload(fetchPayments)
                    }} variant="contained" color="primary" sx={{ml: 3}}>
                        Download CSV
                    </Button>
                </Box>
                {tablesPaginatedObj}
            </Box>
            <Box style={{display: "flex", justifyContent: "space-between", mt: 1}}>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel sx={{mr: 2}} id="paid-by-select">Paid By</InputLabel>
                    <Select
                        labelId="paid-by-select"
                        label="Paid By"
                        value={tableViewMetas.paidByFilter}
                        onChange={handleChangePaidByFilter}
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"none"} value={"none"}>None</MenuItem>
                        <Divider></Divider>
                        {
                            allUsers.map(user => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user.username}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel id="payment-method-label">Payment Method</InputLabel>
                    <Select
                        labelId="payment-method-label"
                        name="paymentMode"
                        value={tableViewMetas.paymentModeFilter}
                        onChange={handlePaymentModeFilterChange}
                        label="Payment Method"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        {paymentModes.map((mode) => (
                            <MenuItem key={mode.key} value={mode.key}>
                                {mode.key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel id="linked-bank-label">Bank link status</InputLabel>
                    <Select
                        labelId="linked-bank-label"
                        name="linkedToBank"
                        value={tableViewMetas.linkedToBankFilter}
                        label="Bank Link status"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                        onChange={handleBankLinkFilterChange}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"linkedToBank"} value={"linkedToBank"}>Linked to bank</MenuItem>
                        <MenuItem key={"notYetLinked"} value={"notYetLinked"}>Not yet linked</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel id="bill-required-label">Bill Required</InputLabel>
                    <Select
                        labelId="bill-required-label"
                        name="billRequired"
                        value={tableViewMetas.billRequiredFilter}
                        label="Bill required"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                        onChange={handleBillRequiredFilterChange}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"yes"} value={"yes"}>Yes</MenuItem>
                        <MenuItem key={"no"} value={"no"}>No</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 150}}>
                    <InputLabel id="bill-due-label">Bill Due</InputLabel>
                    <Select
                        labelId="bill-due-label"
                        name="billDue"
                        value={tableViewMetas.billDueFilter}
                        label="Bill due"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                        onChange={handleBillDueFilterChange}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"yes"} value={"yes"}>Yes</MenuItem>
                        <MenuItem key={"no"} value={"no"}>No</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{minWidth: 220}}>
                    <InputLabel id="linked-bill-label">Bill link status</InputLabel>
                    <Select
                        labelId="linked-bill-label"
                        name="billLinked"
                        value={tableViewMetas.billLinkedFilter}
                        label="Bill Link status"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                        onChange={handleBillLinkFilterChange}
                    >
                        <MenuItem key={"all"} value={"all"}>All</MenuItem>
                        <MenuItem key={"billsAttached"} value={"billsAttached"}>Bills Attached</MenuItem>
                        <MenuItem key={"noBillAttached"} value={"noBillAttached"}>No bill attached</MenuItem>
                        <MenuItem key={"noBillFilesAttached"} value={"noBillFilesAttached"}>Any bill missing invoice files</MenuItem>
                        <MenuItem key={"notYetLinkedToZoho"} value={"notYetLinkedToZoho"}>Bills Attached, Not yet
                            Linked</MenuItem>
                        <MenuItem key={"allLinkedToZoho"} value={"allLinkedToZoho"}>All bills linked to ZOHO</MenuItem>
                        <MenuItem key={"anyLinkedToZoho"} value={"anyLinkedToZoho"}>Any bill linked to ZOHO</MenuItem>
                    </Select>
                </FormControl>
                <DateRangeSelector
                    inputLabel={"'Bank debit' date Range"}
                    handleDateRangeChange={handleBankDebitDateRangeChange}
                    startDate={parseDateString(tableViewMetas.bankDebitStartDate)}
                    endDate={parseDateString(tableViewMetas.bankDebitEndDate)}
                >
                </DateRangeSelector>
                <DateRangeSelector
                    inputLabel={"'Created at' date Range"}
                    handleDateRangeChange={handleCreatedAtDateRangeChange}
                    startDate={parseDateString(tableViewMetas.createdAtStartDate)}
                    endDate={parseDateString(tableViewMetas.createdAtEndDate)}
                >
                </DateRangeSelector>
            </Box>
            <TableContainer component={Paper} sx={{mt: 2}}>
                <Table>
                    <TableHead className={"tableHeader"}>
                        {columns.map((column, index) => (
                            <TableCell key={index}
                                       sx={{
                                           maxWidth: column.width, padding: 0, paddingLeft: "10px",
                                           paddingBottom: "2px", border: "1px solid grey",
                                           backgroundColor: column.color_group ? COLOR_MAP[column.color_group] : "default"
                                       }}>
                                <TableSortLabel
                                    style={{padding: 0}}
                                    active={tableViewMetas.sortBy === column.key}
                                    direction={tableViewMetas.sortBy === column.key ? tableViewMetas.sortOrder : 'asc'}
                                    onClick={() => handleSortByChange(column.key)}
                                    disabled={isNull(column.sortable) ? false : !column.sortable}
                                >
                                    <Tooltip title={column.tooltip_text ? column.tooltip_text : column.name}>
                                        {column.name}
                                    </Tooltip>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {tableDataRows}
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1}}
                open={loading}
                // onClick={handleClose}
            >
                <CircularProgress color="primary"/>
            </Backdrop>
        </Box>
    );
};

export default PaymentDetailsTable;
