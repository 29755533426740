import React from "react";
import {handleApiError, isEmptyString, isNull, notNull} from "../utils/utils";
import {Autocomplete, Checkbox, Link, TableCell, TextField, Typography} from "@mui/material";
import {OpenInNew} from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {axiosInstance} from "../utils/axiosConfig";
import {
    DOWNLOAD_FILE_API,
    GET_ACCOUNT_LIST,
    GET_ALL_USERS, GET_BATCH_LIST, GET_ENKASH_VENDOR_LIST, GET_INVOICE_NUMBER_LIST,
    GET_PAYMENT_MODES_API,
    GET_VENDORS_LIST
} from "../utils/consts";
import {notifyError, notifyInfo, notifySuccess} from "../utils/display.notifications";
import {CustomPopper} from "../components/CustomPopper";

export const getTableCellContent = (data, column, index) => {
    let {key, is_link, is_bool, is_check_box, is_list, link_list, color_group, is_date} = column;

    is_check_box = isNull(is_check_box) ? false : is_check_box;
    is_bool = isNull(is_bool) ? false : is_bool;
    is_link = isNull(is_link) ? false : is_link;
    is_list = isNull(is_list) ? false : is_list;
    link_list = isNull(link_list) ? false : link_list;
    is_date = isNull(is_date) ? false : is_date;

    let content = null;
    if (is_check_box) {
        content = (
            <Checkbox
                onChange={(event) =>
                    this.handleSelectRow(event, data.step_id)}
                checked={this.state.selectedRows.has(data.step_id)}
            >
            </Checkbox>);
    } else {
        content = data[key];
    }
    if (is_link) {
        if (data[key] === "" || data[key] === null || data[key] === undefined) {
            content = "NA";
        } else {
            content = (
                <Link href={data[key]} target="_blank" rel="noopener">
                    <OpenInNew></OpenInNew>
                </Link>);
        }
    } else if (is_bool) {
        if (data[key] === "" || data[key] === null || data[key] === undefined || data[key] === false) {
            content = "N";
        } else if (data[key]) {
            content = "Y";
        }
    } else if (is_list) {
        const items = data[key];
        content = (
            <List>
                {items.map((item, index) => {
                    return (
                        <ListItem key={index}>
                            {
                                (link_list && !isEmptyString(item.link)) ? (
                                    <Link sx={{fontSize: "14px"}} href={item.link} rel="noopener">
                                        {item.name}
                                    </Link>
                                ) : (
                                    <Typography fontSize={'14px'}>
                                        {item.name}
                                    </Typography>
                                )
                            }
                        </ListItem>);
                })}
            </List>
        )
    } else if (is_date) {
        content = data[key]["display_val"];
    }

    return content;
}

export const fetchAllUsersUtil = (setAllUsers) => {
    axiosInstance.get(GET_ALL_USERS)
        .then((response) => {
            setAllUsers(response.data.user_data);
        })
        .catch((error) => {
            console.log(error);
            notifyError("fetchallusers", "Failed to fetch all users");
        })
}

export const fetchAllPaymentModesUtil = (setAllPaymentModes) => {
    axiosInstance.get(GET_PAYMENT_MODES_API)
        .then(response => {
            setAllPaymentModes(response.data);
        })
        .catch(err => {
            notifyError("paymentmode-fetch", "Failed to fetch payment modes.");
        })
}

export const fetchVendorsList = (setVendorsList, searchQuery, maxEntries) => {
    let query = "?";
    if (!isNull(searchQuery)) {
        query += `searchQuery=${searchQuery}`;
    }

    if (!isNull(maxEntries)) {
        query += `&maxEntries=${maxEntries}`;
    }

    axiosInstance.get(GET_VENDORS_LIST + query)
        .then(resp => {
            console.log(resp.data);
            setVendorsList(resp.data.vendors);
        })
        .catch((error) => {
            handleApiError("get_vendors",  error);
        })
}

export const fetchAccountList = (setAccountList, searchQuery) => {
    let query = "?";
    if (!isNull(searchQuery)) {
        query += `searchQuery=${searchQuery}`;
    }

    axiosInstance.get(GET_ACCOUNT_LIST + query)
        .then(resp => {
            setAccountList(resp.data.accounts);
        })
        .catch((error) => {
            console.log(error);
            handleApiError("get_account",  error);
        })
}


export const fetchBatchList = (setBatchList, searchQuery) => {
    let query = "?";
    if (!isNull(searchQuery)) {
        query += `searchQuery=${searchQuery}`;
    }

    axiosInstance.get(GET_BATCH_LIST + query)
        .then(resp => {
            setBatchList(resp.data.batches);
        })
        .catch((error) => {
            console.log(error);
            handleApiError("get_batches",  error);
        })
}

export const fetchEnkashVendorList = (setVendorList, searchQuery, cacheDataCompulsory=false, setLoading = null) => {
    let query = `?cacheDataCompulsory=${cacheDataCompulsory}`;
    if (!isNull(searchQuery)) {
        query += `&searchQuery=${searchQuery}`;
    }

    axiosInstance.get(GET_ENKASH_VENDOR_LIST + query)
        .then(resp => {
            setVendorList(resp.data.enkash_vendors);
            if (setLoading) {
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log(error);
            handleApiError("get_enkash_vendors",  error);
        })
}

export const fetchInvoiceNumberList = (setInvoiceList, searchQuery, setLoading = null) => {
    let query = `?maxEntries=10`;
    if (!isNull(searchQuery)) {
        query += `&searchQuery=${searchQuery}`;
    }

    axiosInstance.get(GET_INVOICE_NUMBER_LIST + query)
        .then(resp => {
            console.log("New Invoice number list", resp.data.invoice_numbers)
            setInvoiceList(resp.data.invoice_numbers);
            if (setLoading) {
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log(error);
            handleApiError("get_invoice_numbers",  error);
        })
}

export const accountSelector = (disabled, accountList, account, handleAccountChange, handleAccountListSearchChange, billId=null) => {
    return <Autocomplete
    options={accountList}
    disabled={disabled}
    getOptionLabel={(option) => option.name}
    name="account"
    value={account}
    onChange={(event, val) => {
        billId ? handleAccountChange(billId, val) : handleAccountChange(val)
    }}
    renderInput={(params) =>
        <TextField {...params} label="Select Account" variant="outlined"
                   onChange={handleAccountListSearchChange}
        />
    }
    PopperComponent={CustomPopper}
    noOptionsText={(
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography>No such account found.</Typography>
        </div>)}
/>;}


export const batchNumberSelector = (disabled, batchList, batch_number, handleBatchChange, handleBatchListSearchChange, billId=null) => {
    return <Autocomplete
        options={batchList}
        disabled={disabled}
        getOptionLabel={(option) => option.id}
        name="batch"
        value={batch_number}
        onChange={(event, val) => {
            billId ? handleBatchChange(billId, val) : handleBatchChange(val)
        }}
        renderInput={(params) =>
            <TextField {...params} label="Select Batch Number" variant="outlined"
                       onChange={handleBatchListSearchChange}
            />
        }
        PopperComponent={CustomPopper}
        noOptionsText={(
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography>No such batch found.</Typography>
            </div>)}
    />;
}

export const enkashVendorSelector = (disabled, vendorList, selectedVendorId, handleVendorChange, handleVendorListSearchChange, billId=null) => {
    const enkashVendor = isNull(vendorList) ? null : vendorList.find(vendor => vendor.company_id === selectedVendorId);
    return <Autocomplete
        options={vendorList}
        disabled={disabled}
        getOptionLabel={(option) => option.legal_name}
        name="enkashVendor"
        value={enkashVendor}
        onChange={(event, val) => {
            billId ? handleVendorChange(billId, val) : handleVendorChange(val)
        }}
        renderInput={(params) =>
            <TextField {...params} label="Select Enkash Vendor" variant="outlined"
                       onChange={handleVendorListSearchChange}
            />
        }
        PopperComponent={CustomPopper}
        noOptionsText={(
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography>No such vendor found.</Typography>
            </div>)}
    />;
}

export const downloadFile = (fileId, appendBillId=true) => {
    notifyInfo("downloadFile", "Starting file download");
    let query = "?fileId=" + fileId + "&appendBillId=" + appendBillId;
    axiosInstance.get(DOWNLOAD_FILE_API + query)
        .then(resp => {
            const {file_name, file_type, file_base64} = resp.data;
            const byteCharacters = atob(file_base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let j = 0; j < byteCharacters.length; j++) {
                byteNumbers[j] = byteCharacters.charCodeAt(j);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: file_type });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.target = '_blank';
            link.download = file_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            notifySuccess("downloadFile", "File downloaded successfully.")
        })
        .catch(err => {
            console.log("Error in downloading file", err);
            handleApiError("downloadFile",  err);
        })
}

export const handleDownload = async (fetchPayments) => {
    const data = await fetchPayments(true);
    const csvData = convertToCSV(data.payment_details, data.columns);
    downloadCSV(csvData);
};

const convertToCSV = (data, columns, delimiter = "|") => {
    // Prepare new columns
    const csvColumns = columns
        .filter(col => col.key !== 'bills') // Remove the original bills column
        .concat([
            { key: 'bills_name', name: 'Bills Name' },
            { key: 'bills_description', name: 'Bills Description' },
            { key: 'bills_account', name: 'Bills Account' }
        ]);

    // Step 2: Transform data to create multiple rows for each bill
    const transformedData = [];

    data.forEach(row => {
        const bills = row['bills'] || [];
        const zohoDetails = {
            amounts: row['zoho_amounts'] || [],
            vendors: row['zoho_vendors'] || [],
            documentLinks: row['zoho_document_links'] || []
        };

        if (bills.length === 0) {
            // If no bills, push the original row with empty values for new columns
            transformedData.push({
                ...row,
                bills_name: '',
                bills_description: '',
                bills_account: '',
                ...zohoDetails.amounts.reduce((acc, amount, index) => ({ ...acc, [`zoho_amounts`]: amount }), {}),
                ...zohoDetails.vendors.reduce((acc, vendor, index) => ({ ...acc, [`zoho_vendors`]: vendor }), {}),
                ...zohoDetails.documentLinks.reduce((acc, link, index) => ({ ...acc, [`zoho_document_links`]: link }), {})
            });
        } else {
            // Create a row for each bill
            bills.forEach((bill, index) => {
                transformedData.push({
                    ...row,
                    bills_name: bill.name || '',
                    bills_description: bill.description || '',
                    bills_account: bill.account || '',
                    ...zohoDetails.amounts[index] ? { [`zoho_amounts`]: zohoDetails.amounts[index] } : {},
                    ...zohoDetails.vendors[index] ? { [`zoho_vendors`]: zohoDetails.vendors[index] } : {},
                    ...zohoDetails.documentLinks[index] ? { [`zoho_document_links`]: zohoDetails.documentLinks[index] } : {}
                });
            });
        }
    });

    // Generate header row
    const header = csvColumns.map(column => column.name || column.key);

    // Process each row
    const rows = transformedData.map(row => {
        return csvColumns.map(column => {
            let value = row[column.key] || '';
            if (column.key_for_csv) {
                value = row[column.key][column.key_for_csv] || '';
            }
            if(column.is_link && value!=="") {
                value = "https://"+window.location.hostname+value+"/";
            }
            return JSON.stringify(value);
        }).join(delimiter);
    });

    // Return CSV as string
    return [header.join(delimiter), ...rows].join('\r\n');
};

const downloadCSV = (csvData, filename = 'data.csv') => {
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(csvBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

