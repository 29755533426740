import {CLEAR_NOTIFICATION, PUSH_NOTIFICATION} from "../constants/global.constant";

export const pushNotification = (notification) => ({
    type: PUSH_NOTIFICATION,
    notification: notification,
});

export const clearNotification = (id) => ({
    type: CLEAR_NOTIFICATION,
    id: id,
});