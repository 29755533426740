import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {Button, Popover} from "@mui/material";
import {isNull} from "../utils/utils";

export const DateRangeSelector = (props) => {
    const {inputLabel, handleDateRangeChange, startDate, endDate} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectionRange, setSelectionRange] = useState({
        startDate: startDate || null,
        endDate: endDate || null,
        key: 'selection',
    });

    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        handleDateRangeChange(selectionRange);
        setAnchorEl(null);
    };

    const handleClear = () => {
        setSelectionRange({
            startDate: null,
            endDate: null,
            key: 'selection',
        });
        handleDateRangeChange({
            startDate: null,
            endDate: null
        });
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-range-popover' : undefined;

    const displayValue =
        selectionRange.startDate && selectionRange.endDate
            ? `${format(selectionRange.startDate, 'dd/MM/yyyy')} - ${format(selectionRange.endDate, 'dd/MM/yyyy')}`
            : null;

    return (
        <Box>
            <TextField
                value={displayValue ? displayValue : ""}
                onClick={handleClick}
                label={inputLabel}
                name={inputLabel}
                InputLabelProps={{
                    shrink: !isNull(displayValue),
                }}
                fullWidth
                inputProps={{
                    sx: {
                        padding: '8px !important',
                    }
                }}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>
                    <DateRange
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        rangeColors={['#3f51b5']}
                        months={1}
                        direction="horizontal"
                        showMonthAndYearPickers={false}
                        preventSnapRefocus={true}
                        calendarFocus="backwards"
                    />
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button onClick={handleClear} color="primary">
                            Clear
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Apply
                        </Button>
                    </Box>
                </Box>

            </Popover>
        </Box>
    );
}