import {Box, Card, CardContent, Link, Typography} from "@mui/material";
import {FileOpen, FileOpenOutlined} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {isEmptyString} from "../../utils/utils";

export const ZohoDetails = (props) => {
    const {zohoDetails} = props;
    const {id, amount, zoho_link, invoice_date, vendor_name, document_link} = zohoDetails;
    const isEmptyId = isEmptyString(id);

    let zohoDetailsContent = (
        <Typography fontSize={'14px'} fontWeight={'bold'}>
            ZOHO Details not yet linked.
        </Typography>
    );
    if (!isEmptyId) {
        zohoDetailsContent = (
            <>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography fontSize={'14px'} fontWeight={'bold'}>
                        Zoho Link:
                    </Typography>
                    {
                        isEmptyString(zoho_link) ? (
                            <Typography fontSize={'14px'}>
                                Missing
                            </Typography>
                        ) : (
                            <Link sx={{marginLeft: "10px"}} href={zoho_link} target="_blank" rel="noopener noreferrer">
                                <FileOpenOutlined fontSize={"14px"}></FileOpenOutlined>
                            </Link>
                        )
                    }
                </Box>
                <Divider></Divider>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                    Zoho Amount(INR): {amount}/-
                </Typography>
                <Divider></Divider>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                    Zoho Invoice Date: {invoice_date}
                </Typography>
                <Divider></Divider>
                <Typography fontSize={'14px'} fontWeight={'bold'}>
                    Zoho Vendor Name: {vendor_name}
                </Typography>
                <Divider></Divider>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography fontSize={'14px'} fontWeight={'bold'}>
                        Zoho Document Link:
                    </Typography>
                    {
                        isEmptyString(document_link) ? (
                            <Typography fontSize={'14px'}>
                                Missing
                            </Typography>
                        ) : (
                            <Link sx={{marginLeft: "10px"}} href={document_link} target="_blank" rel="noopener noreferrer">
                                <FileOpenOutlined fontSize={"14px"}></FileOpenOutlined>
                            </Link>
                        )
                    }
                </Box>
                <Divider></Divider>
            </>
        );
    }

    return (
        <Card sx={{width:'100%', float: "center", padding: '10px', backgroundColor: "#FCF3CF"}} >
            <CardContent sx={{padding: "0px !important"}}>
                {zohoDetailsContent}
            </CardContent>
        </Card>
    );
}