import {
    Autocomplete,
    Box, Button, Card, CardContent,
    FormControl,
    IconButton,
    InputLabel, Link,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ItalicTextDivider from "../../components/ItalicTextDivider";
import FileUploadComponent from "../../components/FileUploadComponent";
import {
    ArrowCircleDown,
    ArrowCircleUp,
    AttachFile,
    CloudDone, ContentCopy,
    DeleteTwoTone,
    OpenInNew,
    PlayCircle
} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {FilesListView} from "../../components/FilesListView";
import {fetchVendorsList} from "../utils";
import {CustomPopper} from "../../components/CustomPopper";
import Divider from "@mui/material/Divider";
import {copyToClipboard, isNull} from "../../utils/utils";
import {axiosInstance} from "../../utils/axiosConfig";
import {GENERATE_IRN} from "../../utils/consts";
import {notifyError, notifySuccess} from "../../utils/display.notifications";
import CreateVendor from "../CreateVendorPopup";

export const PaymentFormCard = (props) => {
    const { index, saved=false, expandCard=true, paymentData, paymentModes,
            handleAdditionalFilesChange, handleChange, toggleCardExpand, setGeneratedIRN, handleVendorSelection,
            onDeleteSavedPaymentEntry, removePaymentEntry, editMode } = props;

    const { id, payment_mode, transaction_number, amount, bank_amount, description,
        additional_files, generatedIRN, vendor  } = paymentData;

    const [showCreateVendorPopup, setShowCreateVendorPopup] = useState(false);
    const [vendorList, setVendorList] = useState([])
    const [vendorListSearchMeta, setVendorListSearchMeta] = useState({
        searchQuery: '',
        maxEntries: 100,
    });
    const fieldsDisabled = saved // && !editMode; Can be changed when we add option to update payments from the same page

    useEffect(() => {
        fetchVendorsList(setVendorList, vendorListSearchMeta.searchQuery, vendorListSearchMeta.maxEntries);
    }, [vendorListSearchMeta]);

    const handleVendorListSearchChange = (e) => {
        setVendorListSearchMeta({
            ...vendorListSearchMeta,
            searchQuery: e.target.value,
        });
    }

    const generateIRN = () => {
        axiosInstance.get(GENERATE_IRN)
            .then((response) => {
                setGeneratedIRN(response.data.irn);
                notifySuccess("createpayment", "IRN Generated");
            })
            .catch((err) => {
                console.log("ERROR in generating IRN: ", err);
                notifyError("createpayment", "Error generating IRN");
            })
    }

    let actionOnPaymentCard = (
        <IconButton onClick={removePaymentEntry}>
            <DeleteTwoTone></DeleteTwoTone>
        </IconButton>
    );

    if (saved) {
        actionOnPaymentCard = (
            <>
                <Tooltip title={"Open payment edit page"}>
                    <Link href={"/payment/edit/" + id} target="_blank">
                        <OpenInNew color={"primary"} sx={{mr: 1}}></OpenInNew>
                    </Link>
                </Tooltip>
                <Tooltip title={"Saved in DB"}>
                    <CloudDone sx={{color: "#1D8348"}}></CloudDone>
                </Tooltip>
                <Tooltip title={"Unlink from Bill"}>
                    <IconButton onClick={onDeleteSavedPaymentEntry} color="error" disabled={fieldsDisabled}>
                        <DeleteTwoTone/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={expandCard ? "Collapse card" : "Expand card"}>
                    <IconButton onClick={toggleCardExpand} color="default">
                        {
                            expandCard ? <ArrowCircleUp/> : <ArrowCircleDown/>
                        }
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    console.log("Generated IRN: ", generatedIRN);
    let formData = null;
    if (!saved || expandCard) {
        formData = (
            <>
                <Divider></Divider>
                <Typography sx={{mt: 2}} variant="body2" fontStyle="italic">
                    Use Below IRN, wherever transaction remarks can be added (ex. Enkash Vendor Payment, ICICI Net
                    Banking). If IRN is used then can skip Transaction ID
                </Typography>
                <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
                    <Typography sx={{
                        fontStyle: "italic", paddingLeft: "8px", paddingRight: "8px",
                        borderRadius: "4px",
                        backgroundColor: isNull(generatedIRN) ? "" : "#196F3D",
                        color: isNull(generatedIRN) ? "#000" : "white"
                    }}
                    >
                        {
                            isNull(generatedIRN) && !fieldsDisabled ? "Generate Internal Ref No." : `Generated IRN: ${isNull(generatedIRN) ? "-" : generatedIRN}`
                        }
                    </Typography>
                    {
                        isNull(generatedIRN) && !fieldsDisabled ? (
                            <IconButton onClick={generateIRN}>
                                <PlayCircle sx={{color: "#196F3D"}}></PlayCircle>
                            </IconButton>) : null
                    }
                    {
                        isNull(generatedIRN) ? null : (
                            <IconButton onClick={() => copyToClipboard(generatedIRN)} sx={{marginLeft: "10px"}}>
                                <ContentCopy></ContentCopy>
                            </IconButton>
                        )
                    }
                </Box>
                <FormControl fullWidth>
                    <InputLabel id="payment-method-label">Payment Method</InputLabel>
                    <Select
                        disabled={fieldsDisabled}
                        labelId="payment-method-label"
                        name="payment_mode"
                        value={payment_mode}
                        onChange={handleChange}
                        label="Payment Method"
                        inputProps={{
                            sx: {padding: "8px !important"}
                        }}
                    >
                        {paymentModes.map((mode) => (
                            <MenuItem key={mode.key} value={mode.key}>
                                {mode.key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography style={{marginTop: 23}} variant='caption'>Find this number from bill</Typography>
                <TextField
                    disabled={!payment_mode || fieldsDisabled}
                    label={payment_mode ?
                        paymentModes.find(mode => mode.key === payment_mode)?.ref_matcher :
                        "First choose a Payment Method"
                    }
                    name="transaction_number"
                    value={transaction_number}
                    onChange={handleChange}
                    fullWidth
                    sx={{mt: 2}}
                    style={{marginTop: "5px"}}
                    inputProps={{
                        sx: {padding: "8px !important"}
                    }}
                />
                <TextField
                    disabled={fieldsDisabled}
                    label="Amount"
                    name="amount"
                    value={amount}
                    onChange={handleChange}
                    fullWidth
                    sx={{mt: 2}}
                    inputProps={{
                        sx: {padding: "8px !important"}
                    }}
                />

                <Autocomplete
                    options={vendorList}
                    disabled={fieldsDisabled}
                    getOptionLabel={(option) => option.name + " - " + option.gst}
                    value={vendor}
                    onChange={(event, val) => {
                        console.log(event);
                        handleVendorSelection(val)
                    }}
                    inputProps={{
                        sx: {padding: "8px !important"}
                    }}
                    size="small"
                    sx={{mt: 1}}
                    renderInput={(params) =>
                        <TextField {...params} label="Select Vendor" variant="outlined"
                                   onChange={handleVendorListSearchChange}/>
                    }
                    PopperComponent={CustomPopper}
                    noOptionsText={(
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Typography>No such vendor found.</Typography>
                            <Button color={"secondary"} onClick={() => setShowCreateVendorPopup(true)}>Create New
                                Vendor</Button>
                        </div>)}
                />

                <TextField
                    disabled={fieldsDisabled}
                    fullWidth
                    multiline
                    label="Description-Fill in Vendor Name, Invoice No. etc (Optional)"
                    name="description"
                    value={description}
                    onChange={handleChange}
                    minRows={2}
                    sx={{mt: 2}}
                />
                <ItalicTextDivider text={"Additional files"}/>
                {
                    <FilesListView files={additional_files} uploaded={saved} showRemoveIcon={false}/>
                }
                {
                    fieldsDisabled ? null : (
                        <FileUploadComponent
                            buttonStyles={{
                                variant: "text", color: 'secondary',
                                sx: {marginTop: '15px'},
                                startIcon: <AttachFile/>,
                            }}
                            text={"Attach More Additional Files"}
                            index={index}
                            files={additional_files}
                            showFiles={false}
                            handleFileChange={e => handleAdditionalFilesChange(Array.from(e.target.files))}
                        />
                    )
                }
            </>
        );
    }

    const idString = `${isNull(transaction_number) ? isNull(generatedIRN) ? "" : `, IRN - ${generatedIRN}` : `, Transaction Number - ${transaction_number}`}`;
    let amountString = `${bank_amount !== "0.00" ? `, Bank Amount - ${bank_amount}` : `, Amount - ${amount}`}`;
    const titleString = `${!saved ? "New " : ""} Payment #${index + 1}:${saved? ` Mode - ${payment_mode}${amountString}${idString}`: ""}`;
    return (
        <>
            <Card onClick={saved && !expandCard ? toggleCardExpand : null}
                  sx={{cursor: saved && !expandCard ? "pointer" : ""}}>
                <CardContent>
                    <Box sx={{mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>{titleString}</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            alignItems: "center"
                        }}>
                            {actionOnPaymentCard}
                        </Box>
                    </Box>
                    {formData}
                </CardContent>
                <CreateVendor open={showCreateVendorPopup} onClose={() => setShowCreateVendorPopup(false)}/>
            </Card>
            <Divider sx={{my: 2}}/>
        </>
    );
}