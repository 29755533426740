import React from 'react';
import { Button, List, ListItem, ListItemText } from '@mui/material';

const FileUploadComponent = (props) => {
  const { disabled, text, buttonStyles, index, files, handleFileChange, showFiles=true,
      allowFiles="image/*,.pdf", multipleAllowed=true } = props;
  return (
    <div>
      <input
        type="file"
        multiple={multipleAllowed}
        onChange={(e) => handleFileChange(e, index)}
        style={{ display: 'none' }}
        id={`file-upload-${index}`}
        accept={allowFiles}
        disabled={disabled}
      />
      <label htmlFor={`file-upload-${index}`}>
        <Button disabled={disabled} variant="contained" component="span" {...buttonStyles}>
          {text}
        </Button>
      </label>
        {
            showFiles ? (<List style={{
                overflowY: 'scroll', maxHeight: 100, marginTop: '5px', scrollbarWidth: 0
            }}>
                {files.map((file, idx) => (
                    <ListItem dense key={idx}>
                        <ListItemText primary={file.name} />
                    </ListItem>
                ))}
            </List>) : null
        }
    </div>
  );
};

export default FileUploadComponent;
