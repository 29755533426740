import './App.css';
import CreatePaymentForm from './pages/PaymentsForm/CreatePaymentForm';
import {Route, Routes, useLocation} from "react-router-dom";
import ProtectedRoute from './routes/protectedRoute';
import Login from './pages/Login';
import SocialAuth from './social-auth';
import PermanentDrawerLeft from './components/Sidebar';
import PaymentDetailsTable from './pages/PaymentDetailsTable';
import {connect, useSelector} from "react-redux";
import EditPaymentForm from "./pages/PaymentsForm/EditPaymentForm";
import {UploadTransactions} from "./pages/UploadTransactions";
import BillDetailsTable from "./pages/BillDetailsTable";
import {UploadBillForm} from "./pages/BillsForm/UploadBillForm";
import {EditBillForm} from "./pages/BillsForm/EditBillForm";
import {Box} from "@mui/material";
import React, {useEffect} from "react";
import {closeSnackbar, enqueueSnackbar, SnackbarProvider, useSnackbar} from 'notistack';
import NotificationProvider from "./components/NotificationProvider";

function App(props) {
    return (
        <Box>
            <PermanentDrawerLeft>
                <Routes>
                        <Route element={<ProtectedRoute/>}>
                            <Route exact path="/" element={<CreatePaymentForm/>}/>
                            <Route exact path="/pay-now" element={<CreatePaymentForm/>}/>
                            <Route exact path="/payment/edit/:id" element={<EditPaymentForm/>}/>
                            <Route exact path="/bill/edit/:id" element={<EditBillForm/>}/>
                            <Route exact path="/pay-later" element={<UploadBillForm/>}/>
                            <Route exact path="/payment-details" element={<PaymentDetailsTable/>}/>
                            <Route exact path="/bill-details" element={<BillDetailsTable/>}/>
                            <Route exact path="/upload_transactions" element={<UploadTransactions/>}/>
                          </Route>
                        <Route exact path="/login" element={<Login/>}/>
                        <Route exact path="/google" element={<SocialAuth/>}/>
                </Routes>
            </PermanentDrawerLeft >
            <SnackbarProvider maxSnack={3} style={{marginTop: "50px"}}>
                <NotificationProvider/>
            </SnackbarProvider>
        </Box>
  );
}

const mapStateToProps = (state) => ({
    notifications: state.globalState.notifications,
});

export default connect(mapStateToProps)(App);
