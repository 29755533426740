import {Box, Button, Modal, Typography} from "@mui/material";

const ConfirmationModal = ({ open, onClose, onConfirm, confirmationText }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 1,
                }}
            >
                <Typography variant="h6" component="h2">
                    Confirm Action
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {confirmationText}
                </Typography>
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" onClick={onClose} >
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={onConfirm} sx={{ ml: 2 }}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;