import {CLEAR_NOTIFICATION, PUSH_NOTIFICATION} from "../constants/global.constant";


const initialState = {
    notifications: [],
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case PUSH_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    action.notification,
                    ...state.notifications.filter(notification => notification.id !== action.notification.id),
                ],
            }

        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications.filter(notification => notification.id !== action.id)],
            }
        default:
            return state;
    }
};

export default globalReducer;