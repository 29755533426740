const {REACT_APP_BACKEND_API_URL} = process.env;

export const GOOGLE_AUTH_API = `${REACT_APP_BACKEND_API_URL}/api/auth/google`;
export const GET_PAYMENT_MODES_API = `${REACT_APP_BACKEND_API_URL}/api/get-payment-modes/`;
export const CREATE_PAYMENT_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/create_payment/`;
export const CREATE_BILL_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/create_bill/`;
export const EDIT_PAYMENT_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/edit_payment/`;
export const EDIT_BILL_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/edit_bill/`;
export const CREATE_ENKASH_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/add_enkash_vendor_invoice_entry/`;
export const GET_PAYMENT_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/get_payment/`;
export const GET_BILL_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/get_bill/`;
export const DELETE_PAYMENT_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/delete_payment/`;
export const REMOVE_BILL_FROM_PAYMENT_API = `${REACT_APP_BACKEND_API_URL}/api/remove_bill_from_payment/`;
export const DELETE_BILL_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/delete_bill/`;
export const DELETE_FILE_ENTRY_API = `${REACT_APP_BACKEND_API_URL}/api/delete_file_entry/`;
export const GENERATE_IRN = `${REACT_APP_BACKEND_API_URL}/api/generate_irn/`;
export const GET_ALL_USERS = `${REACT_APP_BACKEND_API_URL}/api/get_all_users/`;
export const GET_PAYMENT_DETAILS = `${REACT_APP_BACKEND_API_URL}/api/get_payment_details/`;
export const GET_BILL_DETAILS = `${REACT_APP_BACKEND_API_URL}/api/get_bill_details/`;
export const UPLOAD_TRANSACTION_STATEMENTS = `${REACT_APP_BACKEND_API_URL}/api/import_transactions/`;
export const GET_VENDORS_LIST = `${REACT_APP_BACKEND_API_URL}/api/get_vendors/`;
export const GET_ACCOUNT_LIST = `${REACT_APP_BACKEND_API_URL}/api/get_accounts/`;
export const GET_BATCH_LIST = `${REACT_APP_BACKEND_API_URL}/api/get_batches/`;
export const GET_ENKASH_VENDOR_LIST = `${REACT_APP_BACKEND_API_URL}/api/get_enkash_vendors/`;
export const GET_INVOICE_NUMBER_LIST = `${REACT_APP_BACKEND_API_URL}/api/get_invoice_numbers/`;
export const CREATE_VENDOR_API = `${REACT_APP_BACKEND_API_URL}/api/create_vendor/`;
export const DOWNLOAD_FILE_API = `${REACT_APP_BACKEND_API_URL}/api/download_file/`;