import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import {useDispatch} from 'react-redux';
import { googleLogin } from '../redux/actions/login.action';
import {reloadAxiosInstance} from "../utils/axiosConfig";

const SocialAuth = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const values = queryString.parse(location.search);
        const code = values.code ? values.code : null;

        if (code) {
            dispatch(googleLogin(code)).then(() => {
                setTimeout(() => {
                    reloadAxiosInstance();
                    navigate('/');
                }, 500);
            });
        }
    }, [location.search, dispatch, navigate]);

    return (
        <div className="loading-icon-container">
            <div className="loading-icon">
                <div className="loading-icon__circle loading-icon__circle--first"></div>
                <div className="loading-icon__circle loading-icon__circle--second"></div>
                <div className="loading-icon__circle loading-icon__circle--third"></div>
                <div className="loading-icon__circle loading-icon__circle--fourth"></div>
            </div>
            <small className=" text-center mr-2"></small>
        </div>
    );
};

export default SocialAuth;
